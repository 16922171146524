<template>
	<div class="stripe-form vld-parent">
		<loading :active="!stripeLoaded" :is-full-page="false" :background-color="$colorBackground" :color="$colorPrimary"></loading>
		<StripeBilling v-if="stripeLoaded && viewState === 0" :stripe="stripe" @click-next="completePayment" @click-cancel="$emit('cancel')" />
		<StripeCart v-if="stripeLoaded && viewState === 1" :stripe="stripe" :defaultTaxCode="defaultTaxCode" @click-next="$emit('cancel')" @click-cancel="$emit('cancel')" />
	</div>
</template>
<script>
import StripeBilling from "@/components/account/StripeBilling.vue";
import StripeCart from "@/components/account/StripeCart.vue";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import axios from "axios";

export default {
	name: 'StripeForm',
	props: {
		startingState: Number,
		newPlan: Boolean,
	},

	components: {
		StripeBilling,
		StripeCart,
		Loading
	},

	data() {
		return {
			stripeLoaded: false, //Prevent loading of sub components until stripe is ready
			stripe: {}, //Stripe instance

			//State Variables
			viewState: 0,

			//IP Location Info for tax determination
			ipGeo: {},
			defaultTaxCode: 'US-CA', //Default to US California
		}
	},

	mounted() {
		this.viewState = this.startingState;
		//Initialize ipGeo info
		this.getGeoLoc();

		this.initializeStripe();
	},

	computed: {},

	methods: {
		initializeStripe: async function() {
			//Load Stripe from API and setup stripe object
			await this.$loadScript('https://js.stripe.com/v3/')
			this.stripe = await Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY); // eslint-disable-line

			this.stripeLoaded = true;
		},

		completePayment: function() {
			if (this.newPlan) { this.viewState++; } else {
				this.$emit('cancel');
			}
		},

		//Get IP address location info for tax calculation
		getGeoLoc: async function() {
			let isError = false;
			let ipInfo = await axios.get(process.env.VUE_APP_GEOAPI_URL)
				.catch(() => isError = true);

			if (isError || 'error' in ipInfo.data) {
				this.ipGeo = {
					continent_code: "NA",
					continent_name: "North America",
					country_code: "CA",
					country_name: "Canada",
					region_code: "BC",
					region_name: "British Columbia",
					status: "Default Info, Error in IP location call"
				};
			} else {
				this.ipGeo = ipInfo.data;
				this.defaultTaxCode = this.ipGeo.country_code + '-' + this.ipGeo.region_code;
			}
		},

	}
}
</script>
<style>
.stripe-form {
	height: 100%;
	width: 100%;
	max-width: 50rem;
	min-height: 35rem;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
}

.stripe_notifications {
	display: block;
	margin: 1rem .5rem 0rem .5rem;
	text-align: left;
	color: var(--color-error);
}

.stripe_info {
	margin: 1rem;
	margin-top: 0;
	font-size: 1.4rem;
	text-align: left;
	display: block;
}

.stripe-item-confirmed {
	position: absolute;
	top: 0;
	right: -0;
	width: 100%;
	height: 100%;
	display: flex;

	z-index: 10;
}

.stripe-item-confirmed__message {
	margin: auto;
}
</style>
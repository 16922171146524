export class DefaultBike {
	constructor() {
		// NOTE: Any changes to root properties and the firestore class must be updated 
		// ----------------------------------------------------------------------------

		this.brand = '';
		this.modelYear = '2020';
		this.modelName = '';
		this.version = '0.1.0';
		this.author = '';
		this.company = '';

		this.database = 1; //0-public, 1-private, 2-team, 3 - local file

		this.isCheckedOut = false;
		this.isReadOnly = false;

		this.selectedSize = 0;

		this.dateCreated = '2020';
		this.dateUpdated = '2020';
		this.apiVersion = '0.1.0';

		this.points = [
			[0.0, 0.0], //0 BB
			[0.0, 0.0], //1 Rear Wheel
			[0.0, 0.0], //2 Shock 1
			[0.0, 0.0], //3 Shock 2
			[0.0, 0.0], //4 Frame - L1
			[0.0, 0.0], //5 L1 - L2
			[0.0, 0.0], //6 L2 - L3
			[0.0, 0.0], //7 L3 - Frame
			[0.0, 0.0], //8 L4 Start
			[0.0, 0.0], //9 L4 - L5
			[0.0, 0.0], //10 L5 End
			[-50.0, 100.0], //11 Idler
			[0.0, 0.0], //12 Brake Mount to Arm
			[0.0, 0.0], //13 Brake Arm End
			[0.0, 0.0], //14 DAQ 1
			[0.0, 0.0], //15 DAQ 2
			[0.0, 0.0],
			[0.0, 0.0],
			[0.0, 600.0], //18 COG
			[0.0, 0.0], //19 Front Wheel
		];

		this.solverInfo = {
			resolution: 1,
			linkageType: 1,
			bumpHeight: 100,

			//links are refered to from 0-frame
			bbAttachedTo: 0,
			wheelAttachedTo: 2,
			shock1AttachedTo: 0,
			shock2AttachedTo: 3,

			link4AttachedTo: 0,
			link5AttachedTo: 0,
			brakeAttachedTo: 0,
			idlerAttachedTo: 0,
			DAQ1AttachedTo: 0,
			DAQ2AttachedTo: 0,

			usesIdler: 0,
			usesBrakeArm: 0,
			usesPullShock: 0,
			usesDAQ: 0,

			shockLength: 185,
			shockExtLength: 0,
			shockLengthFixed: 0,
			shockStroke: 55,
			forkTravel: 160,

			sliderRef: 0,

			shockFlip: 0,
			shockMirror: 0,

			// Each chip config will have an entry in the array. 
			// The first entry is default
			selectedChip: 0,
			chipConfigs: [new ChipConfig('Default')],
			chipSolution: {},

		};

		this.drivetrainInfo = {
			chainringSize: 32,
			idlerSize: 14,
			cassetteSize: [51],
			tireDiameter: 700,
			tireDiameter_front: 700,
			chainLength: 0.0,
			chainLinks: 0,
			derailleurType: '',
			crankLength: 0,
			usesLowerGuide: 0,
		};

		this.geometry = {
			reach: [475],
			hta: [64.0],
			htl: [110.0],
			forkLength: [581.0],
			offset: [44.0],
			lowerHeadset: [3.0],
			trail: [0.0],
			htt: [0.0],

			stack: [0.0],
			fc: [0.0],
			rc: [430.0],
			cs: [0.0],
			wheelbase: [0.0],

			sta_eff: [76],
			sta_act: [74],
			stl: [410],

			nsh: [785],
			insertion: [250],

			bbDrop: [15.0],
			bbHeight: [0.0],
			bbOffset: [0.0],

			upperHeadset: [5.0],
			stemLength: [40.0],
			stemSpacers: [10.0],
			stemStack: [20.0],
			barHeight: [25.0],
			seatedFit: [0.0],

			riderHeight: [1790.0],
			riderWeight: [735.0],
			cogX: [0.0],
			cogY: [600.0],
		};
	}
}

export class BikeView {
	constructor(color) {
		this.isHovered = false;
		this.changeRecord = []; //Undo/Redo record storage
		this.changeIndex = -1; //Undo/redo location
		this.kinematicData = {}; //All kinematic results
		this.dynamicData = {};
		this.color = color;
		this.springData = {}; //Raw spring force data
		this.simpleCoil = {};
		this.springSettings = {
			//Properties used for display
			wheelTravel: 0,
			shockTravel: 0,
			displaySag: .3,

			//Properties used for calculation
			springType: 0, //Loaded or Simple Coil
			rateType: 0, //Shock Sag, Wheel Sag, Rate
			airRate: 10, // Bar
			coilRate: 100, // N/mm
			shockSag: .3,
			wheelSag: .3,
		}
	}
}

export class ChipConfig {
	constructor(name) {
		this.name = name

		// Primary Point Offsets
		this.primary = [
			[0.0, 0.0], //0 BB
			[0.0, 0.0], //1 Rear Wheel
			[0.0, 0.0], //2 Shock 1
			[0.0, 0.0], //3 Shock 2
			[0.0, 0.0], //4 Frame - L1
			[0.0, 0.0], //5 L1 - L2
			[0.0, 0.0], //6 L2 - L3
			[0.0, 0.0], //7 L3 - Frame
			[0.0, 0.0], //8 L4 Start
			[0.0, 0.0], //9 L4 - L5
			[0.0, 0.0], //10 L5 End
			[0.0, 0.0], //11 Idler
			[0.0, 0.0], //12 Brake Mount to Arm
			[0.0, 0.0], //13 Brake Arm End
			[0.0, 0.0], //14 DAQ 1
			[0.0, 0.0], //15 DAQ 2
			[0.0, 0.0],
			[0.0, 0.0],
			[0.0, 0.0], //18 COG
			[0.0, 0.0], //19 Front Wheel
		];

		// Secondary Point Offsets
		this.secondary = [
			[0.0, 0.0], //0 BB
			[0.0, 0.0], //1 Rear Wheel
			[0.0, 0.0], //2 Shock 1
			[0.0, 0.0], //3 Shock 2
			[0.0, 0.0], //4 Frame - L1
			[0.0, 0.0], //5 L1 - L2
			[0.0, 0.0], //6 L2 - L3
			[0.0, 0.0], //7 L3 - Frame
			[0.0, 0.0], //8 L4 Start
			[0.0, 0.0], //9 L4 - L5
			[0.0, 0.0], //10 L5 End
			[0.0, 0.0], //11 Idler
			[0.0, 0.0], //12 Brake Mount to Arm
			[0.0, 0.0], //13 Brake Arm End
			[0.0, 0.0], //14 DAQ 1
			[0.0, 0.0], //15 DAQ 2
			[0.0, 0.0],
			[0.0, 0.0],
			[0.0, 0.0], //18 COG
			[0.0, 0.0], //19 Front Wheel
		];

		// Geometry Override. If 0 value then no override
		this.rearWheel = 0;
		this.frontWheel = 0;

		//Geometry Offset. Offset values by amount set
		this.stemLength = 0;
		this.stemSpacers = 0;
		this.barHeight = 0;
		this.lowerHeadset = 0;
		this.hta = 0;
		this.reach = 0;

		this.forkLength = 0;
		this.offset = 0;
		this.shockLength = 0;
		this.shockStroke = 0;
	}
}
import { sinD, cosD, tanD } from "@/modules/utility.js"


export function magnitude(p1, p2) {
	return Math.sqrt((p1[0] - p2[0]) ** 2 + (p1[1] - p2[1]) ** 2);
}

export function geoForkRise(totalLength, offset, hta) {
	const drop = sinD(90 - hta) * offset;
	const rise = sinD(hta) * totalLength;
	return rise - drop;
}

export function geoTrail(wheelDia, offset, hta) {
	const x_offset = cosD(90 - hta) * offset;
	const y_offset = sinD(90 - hta) * offset;
	return 1 / tanD(hta) * (wheelDia / 2 - y_offset) - x_offset;
}

export function geoForkRun(totalLength, offset, hta) {
	const lower = cosD((90 - hta)) * offset;
	const upper = cosD(hta) * totalLength;
	return lower + upper;
}

export function geoSeatedFit(reach, stack, hta, stemLength, totalSpacers, barHeight, sta, nsh) {
	const x_bar = reach - cosD(hta) * totalSpacers + cosD(90 - hta) * stemLength;
	const y_bar = stack + sinD(hta) * totalSpacers + sinD(90 - hta) * stemLength + barHeight;

	const x_saddle = -cosD(sta) * nsh;
	const y_saddle = sinD(sta) * nsh;
	return magnitude([x_bar, y_bar], [x_saddle, y_saddle])
}

export function geoHTT(reach, stack, sta) {
	const x_st = stack / tanD(sta);
	return x_st + reach;
}
import Vue from 'vue';
import { firestoreAction } from "vuexfire";
import { auth, db, bikeInfoConverter } from "@/firebaseConfig.js";
import { validateSettings } from "@/modules/validateFile.js";

const debug = false;

const getDefaultState = () => {
	return {
		isMobile: false,

		showSidebarRight: true,
		showSidebarLeft: true,

		//Settings Tab
		isMetric: 1, //Whether or not to show metric or us-std vals
		liveMode: 1,
		splitPlot: 0, //0 single, 1, split side-by-side, 2, split stacked		
		mainPlotState: 0, //Which plot to show
		showForceTooltips: 0, //Show or hide force in tooltips

		//MainPlot settings
		plotYAxis: ['leverage'],
		plotXAxis: 'd_wheel_vert',

		plotYPretty: ['Leverage'],
		plotXPretty: 'Wheel Vertical Travel',

		plotYUnits: [''],
		plotYRound: [1000],
		plotXUnits: 'mm',

		//BikePlot Settings
		coordinateSystem: 0, //0 - frame, 1 - bb, 2 - ground
		bikeImageScale: 1.0,
		bikeImageRotation: 0.0,
		bikeImageURL: '',
		showBikeImage: false,
		imgx: 0,
		imgy: 0,
		plotMoveRes: .5,

		//Error handling
		errorActive: false,
		errorMessages: [],
		errorMsgMax: 2, //Max length of error message array

		//Firebase vars------------------------------------
		user: null,
		userInfo: {}, //Additonal user info from firestore
		userSettings: {},
		claims: {},
		displayName: '',

		fs_location: {},

		fs_public: {},
		fs_private: {},
		fs_team: {},

		publicBikeList: [],
		privateBikeList: [],
		teamBikeList: [],

		publicSpringList: [],
		privateSpringList: [],
		teamSpringList: [],

		teamMemberList: [],

		stripePlanList: [],
		stripeTaxList: [],

		messageList: [], //Firestore message list

		//Database Selection---------------------------------
		loadBikeDB: 1, //0 - public, 1 - private, 2 - team
		loadSpringDB: 0, //0 - public, 1 - private, 2 - team

		saveSpringDB: 0, //0 - public, 1 - private, 2 - team

		isOnline: true, //Check status for electron
	}
};

const state = getDefaultState();

const getters = {
	getViewData: (state) => (item) => { return state[item] },
	getViewSubData: (state) => (item, subItem) => { return state[item][subItem] },
	get_fs_database: (state) => (db_index) => {
		if (db_index === 0) { return state.fs_public; }
		if (db_index === 1) { return state.fs_private; }
		if (db_index === 2) { return state.fs_team; }
	},
};

const mutations = {
	RESETSTATE: (state) => { Object.assign(state, getDefaultState()); },

	RESETUSER: (state, user) => {
		//Double set user to trigger reactive UI on user updates
		Vue.set(state, 'user', null);
		Vue.set(state, 'user', user);
	},

	RESETUSERASYNC: async () => {
		const user = auth.currentUser;

		const tokenRes = await user.getIdTokenResult(true)
		const claims = tokenRes.claims;

		//Double set user to trigger reactive UI on user updates
		Vue.set(state, 'user', null);
		Vue.set(state, 'user', user);
		Vue.set(state, 'claims', claims);
	},

	SETVIEWDATA: (state, payload) => {
		if (debug) { console.log(`${payload.item} will be ${payload.value}`); }
		if ('sub2Item' in payload && payload.subItem !== '') {
			Vue.set(state[payload.item][payload.subItem], payload.sub2Item, payload.value);
		} else if ('subItem' in payload && payload.subItem !== '') {
			// state[payload.item][payload.subItem] = payload.value;
			Vue.set(state[payload.item], payload.subItem, payload.value);
		} else {
			Vue.set(state, payload.item, payload.value);
		}
	},

	SETERROR: (state, message) => {
		if (state.errorMessages.length > state.errorMsgMax) { state.errorMessages.pop(); }
		state.errorMessages.unshift(message);
		state.errorActive = true;
		if (debug) { console.log(message); }
	},

	CLEARERROR: (state, index) => { state.errorMessages.splice(index, 1); }
};

const actions = {
	resetState: ({ commit, dispatch }) => {
		commit('RESETSTATE');
		dispatch('unsyncUserData');
		dispatch('syncStripeInfo');
	},

	resetUser: async ({ commit }, style = 'sync') => {
		const user = auth.currentUser;

		if (style === 'async') {
			let claimsErr = false
			const tokenRes = await user.getIdTokenResult(true)
				.catch(() => { claimsErr = true });
			if (!claimsErr) { commit('SETVIEWDATA', { item: 'claims', value: tokenRes.claims }); }
		}

		commit('RESETUSER', user);
	},

	setViewData: ({ commit }, payload) => { commit('SETVIEWDATA', payload); },

	//Shorthand setter for single properties. 
	setShort: ({ commit }, payload) => {
		const commitPayload = { item: payload[0], value: payload[1], }
		commit('SETVIEWDATA', commitPayload);
	},

	setError: ({ commit }, payload) => { commit('SETERROR', payload); },

	clearError: ({ commit }, index) => { commit('CLEARERROR', index); },

	syncUserData: firestoreAction(async ({ bindFirestoreRef, unbindFirestoreRef, dispatch }) => {
		const user = state.user;
		const claims = state.claims;

		dispatch('setShort', ['fs_private', db.collection('user-data').doc(user.uid)]);

		dispatch('setShort', ['fs_public', db.collection('app-data').doc('public-data')]);

		if (claims.team_id) {
			dispatch('setShort', ['fs_team', db.collection('team-data').doc(claims.team_id)]);
		} else {
			dispatch('setShort', ['fs_team', {}]);
		}

		//Bind user info and load user settings if saved--------------------
		bindFirestoreRef('userInfo', db.collection("user-data").doc(user.uid)).then(() => {
			const settings = state?.userInfo?.x_settings ?? {};
			dispatch('setShort', ['userSettings', validateSettings(settings)]);
		});

		//Bind user messages
		bindFirestoreRef('messageList', db.collection("user-data").doc(user.uid).collection("messages"));

		//Bind public database------------------------------------------------
		bindFirestoreRef('publicBikeList', db.collection("app-data")
			.doc('public-data').collection("bike-info").withConverter(bikeInfoConverter));

		bindFirestoreRef('publicSpringList', db.collection("app-data")
			.doc('public-data').collection("spring-info"));

		//Bind user database--------------------------------------------------
		bindFirestoreRef('privateBikeList', db.collection("user-data")
			.doc(user.uid).collection("bike-info").withConverter(bikeInfoConverter));

		bindFirestoreRef('privateSpringList', db.collection("user-data")
			.doc(user.uid).collection("spring-info"));

		//Bind team database--------------------------------------------------
		if (claims.team_id) {
			bindFirestoreRef('teamBikeList', db.collection("team-data")
				.doc(claims.team_id).collection("bike-info").withConverter(bikeInfoConverter));

			bindFirestoreRef('teamSpringList', db.collection("team-data")
				.doc(claims.team_id).collection("spring-info"));
		} else {
			unbindFirestoreRef('teamBikeList');
			unbindFirestoreRef('teamSpringList');
		}

		//For team admin  we sync the member list-----------------------------
		if (claims.team_id === user.uid) {
			bindFirestoreRef('teamMemberList', db.collection("team-data").doc(claims.team_id)
				.collection('team-members'));
		}
	}),

	unsyncUserData: firestoreAction(({ unbindFirestoreRef }) => {
		unbindFirestoreRef('userInfo');
		unbindFirestoreRef('messageList');

		unbindFirestoreRef('publicBikeList');
		unbindFirestoreRef('privateBikeList');
		unbindFirestoreRef('teamBikeList');

		unbindFirestoreRef('publicSpringList');
		unbindFirestoreRef('privateSpringList');
		unbindFirestoreRef('teamSpringList');
	}),

	syncStripeInfo: firestoreAction(({ bindFirestoreRef }) => {
		bindFirestoreRef('stripePlanList', db.collection(process.env.VUE_APP_STRIPE_PLANS));
		bindFirestoreRef('stripeTaxList', db.collection(process.env.VUE_APP_STRIPE_TAX));
	}),
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
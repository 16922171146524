<template>
	<nav class="navbar bg-gradient no-select">
		<button v-if="$route.name === 'lev' && !isMobile" class="navbar__link sidebar-btn" @click="setShort(['showSidebarLeft', !showSidebarLeft])" aria-label="Toggle Left Side Bar" data-test="nav-show-left">&equiv;</button>
		<!--  -->
		<div v-if="$route.name !== 'lev' && !isMobile" class="navbar__spacer"></div>
		<!--  -->
		<div class="nav__logo">
			<IconLogo size="25px" />
		</div>
		<router-link to="/" class="navbar__link font-logo">syn</router-link>
		<!--  -->
		<div class="divider flex-1"></div>
		<!--  -->
		<!--  -->
		<!--  -->
		<!--  -->
		<button v-if="$route.name === 'lev' && !isMobile" class="navbar__link" @click="openFullscreen">
			<AppIcon type="fullscreen" :stroke="true" />
		</button>
		<button class="navbar__link flex-row" aria-label="Open Login Panel" @click="showNav = !showNav">
			<AppIcon type="menu" :stroke="true" />
		</button>
		<div v-if="user && showNav" class="nav-auth-panel card-shadow" v-click-outside="() => showNav = false">
			<NavPanel @click-link="showNav = false" />
		</div>
		<!--  -->
		<div v-if="$route.name === 'lev' && !isMobile" class="sidebar-btn navbar__link" v-on:click="setShort(['showSidebarRight', !showSidebarRight])" aria-label="Toggle Right Side Bar" data-test="nav-show-right">&equiv;</div>
		<!--  -->
		<div v-if="$route.name !== 'lev' && !isMobile" class="navbar__spacer"></div>
	</nav>
</template>
<script>
import AppIcon from "@/components/icons/AppIcon.vue";
import IconLogo from "@/components/icons/IconLogo.vue";
import NavPanel from "@/components/NavPanel.vue";

import vClickOutside from "v-click-outside";
import { mapActions, mapState } from "vuex";

export default {
	name: "Navbar",
	components: {
		AppIcon,
		NavPanel,
		IconLogo
	},

	props: {
		linksActive: Boolean,
	},

	directives: {
		clickOutside: vClickOutside.directive
	},

	watch: {
		'user': {
			handler() {
				this.showNav = false;
			}
		}
	},

	data() {
		return {
			showMsg: false,
			showNav: false,
			isFullscreen: false,
		}
	},

	computed: {
		...mapState('stateViewLev', ['user', 'isMobile', 'showSidebarLeft', 'showSidebarRight']),
	},

	methods: {
		...mapActions('stateViewLev', ['setShort']),

		openFullscreen: function() {
			if (window.innerHeight == screen.height) {
				this.closeFullscreen();
				return;
			}
			const elem = document.documentElement;
			if (elem.requestFullscreen) {
				elem.requestFullscreen();
			} else if (elem.mozRequestFullScreen) { /* Firefox */
				elem.mozRequestFullScreen();
			} else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
				elem.webkitRequestFullscreen();
			} else if (elem.msRequestFullscreen) { /* IE/Edge */
				elem.msRequestFullscreen();
			}
		},

		closeFullscreen: function() {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.mozCancelFullScreen) { /* Firefox */
				document.mozCancelFullScreen();
			} else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
				document.webkitExitFullscreen();
			} else if (document.msExitFullscreen) { /* IE/Edge */
				document.msExitFullscreen();
			}
		}
	}
};
</script>
<style>
.navbar {
	width: 100%;
	height: 5rem;
	min-height: 5rem;
	display: flex;

	color: var(--color-font-primary);
	border: none;

	transition: .25s;
}

.navbar__spacer {
	width: 5rem;
}

.navbar__link {
	width: 5rem;
	padding: 0 .75rem;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	border: none;
	fill: var(--color-font-primary);
	stroke: var(--color-font-primary);

	transition: .3s;
}

.navbar__link:hover {
	color: var(--color-font-bg);
	background-color: var(--color-bg);

	fill: var(--color-font-bg);
	stroke: var(--color-font-bg);
}

.nav-auth-panel {
	position: absolute;
	width: 27rem;
	right: 5rem;
	top: 5rem;
	display: flex;
	overflow: hidden;
	z-index: 90;

	border-radius: .3rem;
}

.sidebar-btn {
	font-size: 2rem;
	font-weight: 400;
}

.nav__logo {
	display: flex;
	align-items: center;

	fill: var(--color-font-primary);
	padding: 5px 5px 5px 15px
}

@media(max-width: 500px) {
	.navbar__link {
		position: sticky;
		top: 0;
	}

	.nav-auth-panel {
		width: 94vw;
		right: 3vw;
	}
}
</style>
import Vue from 'vue';

const debug = false;

const getDefaultState = () => {
	return {
		optimizeEnabled: 0,

		targets: {
			lev: {
				name: 'Leverage',
				key: 'leverage', //Key for python result matrix
				used: false,
				weight: .9,
				points: [],
				color: '#47095b',
				x: [],
				y: [],
			},
			as: {
				name: 'Antisquat',
				key: 'antisquat',
				used: false,
				weight: .1,
				points: [],
				color: '#693579',
				x: [],
				y: [],
			},
			ar: {
				name: 'Antirise',
				key: 'antirise',
				used: false,
				weight: .1,
				points: [],
				color: '#8b5c98',
				x: [],
				y: [],
			},
			pk: {
				name: 'Pedal Kickback',
				key: 'pedal_kickback',
				used: false,
				weight: .1,
				points: [],
				color: '#ae84b8',
				x: [],
				y: [],
			},
		},

		fixShockLength: false,
		fixShockStroke: false,
		shockAngleLimit: [-30, 30],

		pointLimits: [
			[-1, 1, -1, 1],
			[-1, 1, -1, 1],
			[-1, 1, -1, 1],
			[-1, 1, -1, 1],
			[-1, 1, -1, 1],
			[-1, 1, -1, 1],
			[-1, 1, -1, 1],
			[-1, 1, -1, 1],
			[-1, 1, -1, 1],
			[-1, 1, -1, 1],
			[-1, 1, -1, 1],
			[-1, 1, -1, 1],
		],

		pointLimitStatus: [
			['Shock 1', true, false],
			['Shock 2', true, true],
			['Frame - L1', true, true],
			['L1 - L2', false, true],
			['L2 - L3', false, true],
			['L3 - Frame', false, true],
			['L4 Start', false, true],
			['L4 - L5', false, true],
			['L5 Start', false, true],
			['Idler', false, true],
			['Brake Arm 1', false, true],
			['Brake Arm 2', false, true],
		],

		springSettings: {},
	}
};

const state = getDefaultState();

const getters = {};

const mutations = {
	RESETSTATE: (state) => { Object.assign(state, getDefaultState()); },

	SETOPTDATA: (state, payload) => {
		if (debug) {
			console.log(`${payload.item}-${payload.subItem ? payload.subItem : ''} will be ${payload.value}`);
		}
		if ('subItem' in payload && payload.subItem !== '') {
			Vue.set(state[payload.item], payload.subItem, payload.value);
		} else {
			Vue.set(state, payload.item, payload.value);
		}
	},

	SETTARGETDATA: (state, payload) => {
		if (debug && payload.subItem !== 'points') {
			console.log(`${payload.item}-${payload.subItem ? payload.subItem : ''} will be ${payload.value}`);
		}
		if ('subItem' in payload && payload.subItem !== '') {
			Vue.set(state.targets[payload.item], payload.subItem, payload.value);
		} else {
			Vue.set(state.targets, payload.item, payload.value);
		}
	},

	SETPOINTLIMIT: (state, payload) => {
		if (debug) { console.log(`${payload.row} - ${payload.column} will be ${payload.value}`); }
		Vue.set(state.pointLimits[payload.row], payload.column, payload.value);
	},

	SETPOINTSTATE: (state, payload) => {
		Vue.set(state.pointLimitStatus[payload.row], 2, payload.value);
	}
};

const actions = {
	resetState: ({ commit }) => { commit('RESETSTATE'); },

	setOptData: ({ commit }, payload) => { commit('SETOPTDATA', payload); },

	setTargetData: ({ commit }, payload) => { commit('SETTARGETDATA', payload); },

	setPointTable: ({ commit }, payload) => {
		if (debug) { console.log(payload); }
		commit(payload.commit, payload);
	},

};


export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
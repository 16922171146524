import { copy } from "@/modules/utility.js";

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import "firebase/performance";
import "firebase/storage";
import "firebase/auth";

import axios from "axios";

const fb_config = {
	apiKey: "AIzaSyCQUDoM40mo5nehdpN_ehcITZWKJHPyxyc",
	authDomain: "login.syn.bike",
	databaseURL: "https://synfire-2020.firebaseio.com",
	projectId: "synfire-2020",
	storageBucket: "synfire-2020.appspot.com",
	messagingSenderId: "390088636586",
	appId: "1:390088636586:web:54ff1aa0c8a11a284038d3"
};

//Initialize firebase
firebase.initializeApp(fb_config);

const debug = false;

//Initialize perf monitoring
export const perf = firebase.performance();

// Get a Firestore instance
export const db = firebase.firestore();

//Initialize firebase auth
export const auth = firebase.auth();

//Initialize storage 
export const storage = firebase.storage();

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const { Timestamp, GeoPoint } = firebase.firestore
export { Timestamp, GeoPoint }

//DB DATA CONVERSION--------------------------------------------------//
//--------------------------------------------------------------------//

//Bike info constructor class
class FirestoreBikeInfo {
	constructor(bikeObj) {
		let year = bikeObj.modelYear;
		year = year.toString();

		this.author = bikeObj.author;
		this.company = bikeObj.company;

		this.brand = bikeObj.brand;
		this.modelYear = year;
		this.modelName = bikeObj.modelName;
		this.version = bikeObj.version;

		this.isCheckedOut = bikeObj.isCheckedOut;
		this.isReadOnly = bikeObj.isReadOnly;
		this.database = bikeObj.database;

		this.selectedSize = bikeObj.selectedSize;

		this.apiVersion = bikeObj.apiVersion;
		this.dateCreated = bikeObj.dateCreated;
		this.dateUpdated = bikeObj.dateUpdated;
	}
}

//Firestore convert function
export const bikeInfoConverter = {
	toFirestore: function(bikeObj) {
		return {
			brand: bikeObj.brand,
			modelYear: bikeObj.modelYear,
			modelName: bikeObj.modelName,
			version: bikeObj.version,
			author: bikeObj.author,
			company: bikeObj.company,
			database: bikeObj.database,

			isCheckedOut: bikeObj.isCheckedOut,
			isReadOnly: bikeObj.isReadOnly,

			selectedSize: bikeObj.selectedSize,

			apiVersion: bikeObj.apiVersion,
			dateCreated: bikeObj.dateCreated,
			dateUpdated: bikeObj.dateUpdated,
		};
	},
	fromFirestore: function(snapshot, options) {
		const data = snapshot.data(options);
		return new FirestoreBikeInfo(data);
	},
}


//Bike data constructor class
//Bike data takes the UI object for a bike and converts it into a data subset suitable 
//	for storage in Cloud Firestore
class FirestoreBikeData {
	constructor(bikeObj) {
		this.points = Object.values(bikeObj.points); //Convert object to array
		this.solverInfo = bikeObj.solverInfo;

		//If the chip configs are a string then parse
		//	This occurs when coming from firestore
		//	The check is required to prevent parsing when going to firestore
		if (typeof this.solverInfo.chipConfigs === 'string') {
			this.solverInfo.chipConfigs = JSON.parse(this.solverInfo.chipConfigs)
			this.solverInfo.chipSolution = JSON.parse(this.solverInfo.chipSolution)
		}

		this.drivetrainInfo = bikeObj.drivetrainInfo;
		this.geometry = bikeObj.geometry;
	}
}

//Firestore convert function
export const bikeDataConverter = {
	toFirestore: function(bikeObj) {
		const solverInfo = copy(bikeObj.solverInfo) //Likely not needed due to being passed class instance
		solverInfo.chipConfigs = JSON.stringify(solverInfo.chipConfigs)
		solverInfo.chipSolution = JSON.stringify(solverInfo.chipSolution)

		return {
			points: { ...bikeObj.points }, //Convert nested array to arrays in object
			solverInfo: solverInfo,
			drivetrainInfo: bikeObj.drivetrainInfo,
			geometry: bikeObj.geometry,
		};
	},
	fromFirestore: function(snapshot, options) {
		const data = snapshot.data(options);
		return new FirestoreBikeData(data);
	},
}


//Springs won't use full firebase conversion functions, there is no nested arrays
//Get subset of spring data for info block similiar to firebase converter
function getSpringInfo(spring) {
	const springInfo = {
		dateUpdated: spring.dateUpdated,
		brand: spring.brand,
		modelYear: spring.modelYear,
		modelName: spring.modelName,
		shockLength: spring.shockLength,
		shockStroke: spring.shockStroke
	}

	if ('author' in spring) {
		springInfo.author = spring.author;
		springInfo.dateCreated = spring.dateCreated;
	}

	return springInfo;
}


//DB ACCESS METHODS---------------------------------------------------//
//--------------------------------------------------------------------//
//BIKE ACCESS-------------------------------------
//Load bike from database after succesful search
export async function fs_loadBike(key, fs_loc) {
	const res = {
		error: false,
		errorCode: '',
		bikeObj: {},
	}
	await fs_loc.collection('bike-info').doc(key).withConverter(bikeInfoConverter)
		.get()
		.then(function(docObj) {
			if (docObj.exists) {
				res.bikeObj = Object.assign(res.bikeObj, docObj.data());
			} else {
				res.error = true;
			}
		})
		.catch(function(error) {
			res.errorCode = error.code;
			res.error = true;
		});

	await fs_loc.collection('bike-data').doc(key).withConverter(bikeDataConverter)
		.get()
		.then(function(docObj) {
			if (docObj.exists) {
				res.bikeObj = Object.assign(res.bikeObj, docObj.data());
				// res.bikeObj = { ...docObj.data() }; //Incorrectly populates array
			} else {
				res.error = true;
			}
		})
		.catch(function(error) {
			res.errorCode = error.code;
			res.error = true;
		});

	return res;
}

//Check if bike exists in db
export async function fs_checkBikeExist(bike, fs_loc) {
	const res = {
		exists: true, //Assume existence
		id: '',
		errorCode: 'no_error',
		error: false,
	}

	//Handle empty firestore locations
	if (Object.keys(fs_loc).length === 0) {
		res.error = true;
		res.errorCode = 'no-db'
		return res;
	}

	await fs_loc.collection('bike-info').where('brand', '==', bike.brand)
		.where('modelYear', '==', bike.modelYear)
		.where('modelName', '==', bike.modelName)
		.where('version', '==', bike.version)
		.get({ source: "server" })
		.then(function(querySnapshot) {
			if (querySnapshot.empty) {
				res.exists = false;
			} else {
				res.id = querySnapshot.docs[0].id;
			}
		})
		.catch(function(error) {
			res.errorCode = error.code;
			res.error = true;
		});
	return res;
}

export async function fs_saveBike(bike, exists, bikeID, fs_loc) {
	let res = {
		error: false,
		errorCode: '',
	};

	let tempDateCreated = bike.dateCreated;
	let tempDateUpdated = bike.dateUpdated;

	//Set timestamps on save/create
	if (exists) {
		bike.dateUpdated = firebase.firestore.FieldValue.serverTimestamp();
	} else {
		bike.author = auth.currentUser.displayName;
		bike.dateCreated = firebase.firestore.FieldValue.serverTimestamp();
		bike.dateUpdated = firebase.firestore.FieldValue.serverTimestamp();
	}

	//Add or update the bikeInfo and insure valid ID is created
	if (exists) {
		await fs_loc.collection('bike-info').doc(bikeID) //Set info
			.withConverter(bikeInfoConverter)
			.set(new FirestoreBikeInfo(bike))
			.catch(function(error) {
				if (debug) { console.log(error); }
				res.errorCode = error.code;
				res.error = true;
			})
		await fs_loc.collection('bike-data').doc(bikeID) //Set data
			.withConverter(bikeDataConverter)
			.set(new FirestoreBikeData(bike))
			.catch(function(error) {
				if (debug) { console.log(error); }
				res.errorCode = error.code;
				res.error = true;
			})
	} else {
		bikeID = '';
		await fs_loc.collection('bike-info').withConverter(bikeInfoConverter) //Set info then use returned ID to set data
			.add(new FirestoreBikeInfo(bike))
			.then(async function(docRef) {
				// Now set data with same ID
				fs_loc.collection('bike-data').doc(docRef.id)
					.withConverter(bikeDataConverter)
					.set(new FirestoreBikeData(bike))
					.catch(function(error) {
						if (debug) { console.log(error); }
						res.errorCode = error.code;
						res.error = true;
					})
			}).catch(function(error) {
				if (debug) { console.log(error); }
				res.errorCode = error.code;
				res.error = true;
			})
	}

	//Firestore Field values must be removed to allow data serialization
	bike.dateCreated = tempDateCreated;
	bike.dateUpdated = tempDateUpdated;
	return res;
}

export async function fs_deleteBike(bikeID, fs_loc) {
	let res = { info: true, data: true };

	await fs_loc.collection('bike-info').doc(bikeID).delete()
		.catch(() => { res.info = false; });
	await fs_loc.collection('bike-data').doc(bikeID).delete()
		.catch(() => { res.data = false; });

	return res;
}

//SPRING ACCESS-----------------------------------
//Load spring from database after succesful search
export async function fs_loadSpring(key, fs_loc) {
	const res = {
		error: false,
		errorCode: '',
		springObj: {},
	}

	await fs_loc.collection('spring-data').doc(key).get()
		.then(function(docObj) {
			if (docObj.exists) {
				res.springObj = Object.assign(res.springObj, docObj.data());
			} else {
				res.error = true;
			}
		})
		.catch(function(error) {
			res.errorCode = error.code;
			res.error = true;
		});

	return res;
}

//Check if spring exists in db
export async function fs_checkSpringExist(spring, fs_loc) {
	const res = {
		exists: true, //Assume existence
		id: '',
		errorCode: 'no_error',
		error: false,
	}
	await fs_loc.collection('spring-info')
		.where('brand', '==', spring.brand)
		.where('modelYear', '==', spring.modelYear)
		.where('modelName', '==', spring.modelName)
		.where('shockLength', '==', spring.shockLength)
		.where('shockStroke', '==', spring.shockStroke)
		.get({ source: "server" })
		.then(function(querySnapshot) {
			if (querySnapshot.empty) {
				res.exists = false;
			} else {
				res.id = querySnapshot.docs[0].id;
			}
		})
		.catch(function(error) {
			res.errorCode = error.code;
			res.error = true;
		});
	return res;
}

export async function fs_saveSpring(spring, exists, springId, fs_loc) {
	let res = {
		error: false,
		errorCode: '',
	};

	if (debug) { console.log(springId); }

	let tempDateCreated = spring.dateCreated;
	let tempDateUpdated = spring.dateUpdated;


	//Set timestamps on save/create
	spring.apiVersion = '0.1.0';
	if (exists) {
		spring.dateUpdated = firebase.firestore.FieldValue.serverTimestamp();
	} else {
		spring.author = auth.currentUser.displayName;
		spring.dateCreated = firebase.firestore.FieldValue.serverTimestamp();
		spring.dateUpdated = firebase.firestore.FieldValue.serverTimestamp();
	}

	//Add or update the bikeInfo and insure valid ID is created
	if (exists) {
		await fs_loc.collection('spring-info').doc(springId)
			.set(getSpringInfo(spring), { merge: true })
			.catch(function(error) {
				res.errorCode = error.code;
				res.error = true;
			})
		await fs_loc.collection('spring-data').doc(springId).set(spring, { merge: true })
			.catch(function(error) {
				res.errorCode = error.code;
				res.error = true;
			})
	} else {
		springId = '';
		await fs_loc.collection('spring-info').add(getSpringInfo(spring))
			.then(async function(docRef) {
				// Now set data with same ID
				fs_loc.collection('spring-data').doc(docRef.id)
					.set(spring)
					.catch(function(error) {
						res.errorCode = error.code;
						res.error = true;
					})
			})
			.catch(function(error) {
				res.errorCode = error.code;
				res.error = true;
			})
	}

	spring.dateCreated = tempDateCreated;
	spring.dateUpdated = tempDateUpdated;
	return res;
}

export async function fs_deleteSpring(bikeID, fs_loc) {
	let res = { info: true, data: true };

	await fs_loc.collection('spring-info').doc(bikeID).delete()
		.catch(() => { res.info = false; });
	await fs_loc.collection('spring-data').doc(bikeID).delete()
		.catch(() => { res.data = false; });

	return res;
}

//USER DATA MANAGEMENT------------------------------------------------//
//--------------------------------------------------------------------//
export async function fs_reset_pass(email) {
	let res = 'success'
	await auth.sendPasswordResetEmail(email).catch((err) => { res = err.code; });
	return res;
}

export async function fs_cred_login(event, cred) {
	let res = 'success';

	const credential = firebase.auth.AuthCredential.fromJSON(cred.credential)
	await auth.signInWithCredential(credential).catch((err) => { res = err.code; });

	return res
}

export async function fs_google_login() {
	let res = 'success';

	const provider = new firebase.auth.GoogleAuthProvider();
	provider.setCustomParameters({
		prompt: 'select_account',
	});
	provider.addScope('profile');
	provider.addScope('email');

	await auth.signInWithPopup(provider).catch((err) => { res = err.code; });

	return res;
}

export async function fs_ms_login() {
	let res = {
		msg: 'success',
		error: false,
		code: '',
	}
	const provider = new firebase.auth.OAuthProvider('microsoft.com');
	provider.setCustomParameters({
		// Force re-consent.
		prompt: 'consent',
	});
	await auth.signInWithPopup(provider)
		.catch((err) => {
			console.log(err)
			res.code = err.code;
			res.error = true;
			res.msg = err.message;
		});

	return res;
}

export async function fs_email_login(email, password) {
	let res = 'success';
	await auth.signInWithEmailAndPassword(email, password)
		.catch((err) => { res = err.code; });

	return res;
}

export async function fs_email_signup(email, password, displayName) {
	let res = 'success';

	await auth.createUserWithEmailAndPassword(email, password)
		.catch((err) => { res = err.code; });
	if (res !== 'success') { return res; }

	//Create default photo for email users
	const patternCall = await axios.get(process.env.VUE_APP_UTIL_PATTERN_URL)
	const storageRef = storage.ref('userImages/' + auth.currentUser.uid)

	await storageRef.putString(patternCall.data, 'data_url')
		.catch((err) => { res = err.code });
	if (res !== 'success') { return res; }

	const photoURL = await storageRef.getDownloadURL().catch((err) => { res = err.code });
	if (res !== 'success') { return res; }

	await fs_update_userInfo({ displayName, photoURL })
		.catch((err) => { res = err.code; });

	await auth.currentUser.sendEmailVerification()
		.catch((err) => { res = err.code; });

	return res;
}

export async function fs_email_validate() {
	let res = 'success';
	await auth.currentUser.sendEmailVerification()
		.catch((err) => { res = err.code; });

	return res
}

export async function fs_email_change(newEmail, oldEmail, password) {
	let res = 'success';

	await auth.signInWithEmailAndPassword(oldEmail, password)
		.catch((err) => { res = err.code; });
	if (res !== 'success') { return res; }

	await auth.currentUser.updateEmail(newEmail)
		.catch((err) => { res = err.code });

	return res;
}

export async function fs_update_userInfo(info) {
	let res = 'success';
	await auth.currentUser.updateProfile(info)
		.catch((err) => { res = err.code });

	//Check for team and update team info if needed
	if (res === 'success') {
		const tokenRes = await auth.currentUser.getIdTokenResult(true)
			.catch((err) => { console.log(err); })

		if (tokenRes.claims.team_id) {
			//Firestore rules will limit setting permissions to only name and picture
			const docPath = 'team-data/' + tokenRes.claims.team_id + '/team-members/' + auth.currentUser.uid;
			const teamUser = {
				'display_name': auth.currentUser.displayName,
				'picture': auth.currentUser.photoURL,
			}
			await db.doc(docPath).update(teamUser)
				.catch((err) => { console.log(err); });
		}

	}

	return res;
}

export async function fs_update_userImg(file) {
	let res = 'success'
	if (file.size / 1024 / 1024 > 5) { return 'largeFile'; }

	//Get storage reference
	const storageRef = storage.ref('userImages/' + auth.currentUser.uid)
	//Upload picture
	await storageRef.put(file).catch((err) => { res = err.code });
	if (res !== 'success') { return res; }

	//Get url of file
	const url = await storageRef.getDownloadURL().catch((err) => { res = err.code });
	if (res !== 'success') { return res; }

	//Update profile
	await fs_update_userInfo({ photoURL: url }).catch((err) => { res = err.code });

	return res;
}

//Save additional data to firestore
export async function fs_save_userData(data) {
	let res = 'success'

	await db.collection("user-data").doc(auth.currentUser.uid)
		.set(data, { merge: true }).catch((err) => { res = err.code });
	return res;
}

export async function fs_get_userData() {
	let res = '';
	const result = await db.collection("user-data").doc(auth.currentUser.uid)
		.get().catch((err) => { res = err.code });

	res = Object.assign({}, result.data());

	return res;
}

//MESSAGE MANAGEMENT--------------------------------------------------//
//--------------------------------------------------------------------//
export async function fs_remove_message(msg_id) {
	let res = { error: false };

	let docRef = 'user-data/' + auth.currentUser.uid + '/messages/' + msg_id;
	if (debug) { console.log(docRef); }
	await db.doc(docRef).delete()
		.catch(() => { res.error = true; });

	return res;
}

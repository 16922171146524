<template>
	<div class="landing--auth">
		<div class="landing__card--auth card-shadow">
			<div class="landing__login-image">
				<div class="landing__login-icon card-shadow">
					<IconLogo />
				</div>
			</div>
			<!-- <h3 class="">Purchase</h3> -->
      <p class="margin--top-bottom">
        Subscription has expired. The majority of features were made available
        on a free license for non-commercial usage. You can check it out
        <a href="https://app.syn.bike" target="_blank" style="text-decoration: underline">here.</a>
      </p>
			<div class="multi-button-vert-cont padding" style="width: 100%">
				<ButtonStandard v-if="isElectron" name="Upgrade Plan" :flex="true" @click="linkToBilling" />
				<ButtonStandard v-else name="Upgrade Plan" :flex="true" @click="linkToBilling" />
			</div>
			<a :href="pricingURL" target="_blank" class=" border--bottom margin--top-bottom">Check out the pricing</a>
		</div>
		<!-- Update Stripe Info Modal -->
		<ModalWindow :show="stripeModal" @close="closeStripeModal" maxWidth="50rem" minWidth="50rem" :noClose="true" :hideContent="hideStripeContent" :padding="'1rem 2rem 2rem 2rem'">
			<template v-slot:body>
				<StripeForm :startingState="0" @cancel="hideStripeContent = true" :newPlan="true" />
			</template>
		</ModalWindow>
	</div>
</template>
<script>
import ButtonStandard from "@/components/functional/ButtonStandard.vue";
import ModalWindow from "@/components/ModalWindow.vue";
import StripeForm from "@/components/account/StripeForm.vue";
import IconLogo from "@/components/icons/IconLogo.vue";

import { mapState } from "vuex";

export default {
	name: 'LandingExpired',
	components: {
		ButtonStandard,
		ModalWindow,
		StripeForm,
		IconLogo,
	},

	data() {
		return {
			isElectron: process.env.IS_ELECTRON,
			pricingURL: process.env.VUE_APP_PRICING_PAGE,
			stripeModal: false,
			hideStripeContent: false,

			buttonStyle: {
				width: 'auto',
				padding: '0 1rem',
			},
		}
	},

	computed: {
		...mapState('stateViewLev', ['user', 'userInfo', 'isMobile']),
	},

	methods: {
		closeStripeModal: function() {
			this.stripeModal = false;
		},

		openStripeModal: function() {
			this.hideStripeContent = false;
			this.stripeModal = true;
		},

		linkToBilling: function() { window.location = "https://app.syn.bike/account"; }
	}
}
</script>

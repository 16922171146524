var render = function render(_c,_vm){return _c('button',{staticClass:"btn-std",class:{
		'btn-std--flex' : _vm.props.flex, 
		'btn-std--outline' : _vm.props.outline, 
		'btn-std--clear' : _vm.props.clear, 
		'btn-std--right' : _vm.props.right, 
		'btn-std--left' : _vm.props.left, 
		'btn-std--spinner' : _vm.props.spinner, 
		'btn-std--error' : _vm.props.error, 
		'btn-std--outline-error': _vm.props.error && _vm.props.outline, 
		'btn-std--clear-error': _vm.props.error && _vm.props.clear, 
		'btn-std--disabled' : _vm.props.disabled},style:(_vm.props.btnstyle),attrs:{"data-test":_vm.props.test},on:{"click":function($event){_vm.listeners['click'] ? _vm.listeners['click']($event) : ()=>{}}}},[_vm._t("default",function(){return [_c('span',{staticClass:"btn-std__text"},[_vm._v(_vm._s(_vm.props.name))])]}),_c('div',{staticClass:"btn-std__icon-cont"},[_vm._t("icon-slot")],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
	<div v-resize="onResize" ref="maincont" class="container-main">
		<div class="db-table-cont card-shadow flex-column" ref="maintable">
			<DisplayTable :bodyList="displayList" :header="displayHeaders" :buttonStates="buttonStates" :setup="setup" @click="headerSort" v-on:event-click-select="selectClick" v-on:event-click-header="selectAllNone" />
			<!--  -->
			<!--  -->
			<div class="db-control-cont">
				<DirButtonCont>
					<template v-slot:slot-1>
						<ButtonStandard :name="databaseType === 0 ? 'Delete Bikes' : 'Delete Springs'" :error="true" :flex="true" @click="setDeleteModal" />
					</template>
					<template v-slot:slot-2>
						<ButtonStandard :name="databaseType === 0 ? 'Show Springs' : 'Show Bikes'" @click="toggleDB" :outline="true" :flex="true" />
					</template>
					<template v-slot:slot-3>
						<ListSelector v-if="claims.team_perm > 1 || claims.app_admin" :itemList="dbOptions" v-model="dbSelect" @submit="changeDatabase" />
					</template>
				</DirButtonCont>
			</div>
		</div>
		<!--  -->
		<!--  -->
		<ModalWindow :show="deleteModal" @close="setDeleteModal" maxWidth="50rem" minWidth="20rem">
			<template v-slot:header>
				<h5>Confirm Delete:</h5>
			</template>
			<template v-slot:body>
				<div class="cont-bike-delete-list">
					<div v-if="noItemsSelected < 0" style="height: 2rem;">No Items Selected</div>
					<template v-for="item in activeList">
						<transition name="slide-out" :key="item.id+'trans'">
							<div class="list-delete-bikes" :key="item.id" v-if="item.buttonState">
								<span class="list-delete-bikes__text">{{deleteText(item)}}</span>
								<div class="cancel-bike-icon shadow-hover" @click="removeDeleteItem(item.id)">
									<IconClose />
								</div>
							</div>
						</transition>
					</template>
				</div>
				<ButtonStandard name="Confirm Delete" :error="true" @click="deleteBike" :btnstyle="{'width' : '20rem', 'margin': 'auto'}" />
				<span class="margin--top">This operation is irreversible. All data will be removed from the universe.</span>
			</template>
		</ModalWindow>
	</div>
</template>
<script>
import ModalWindow from "@/components/ModalWindow.vue";
import ButtonStandard from "@/components/functional/ButtonStandard.vue";
import ListSelector from "@/components/functional/ListSelector.vue"
import DisplayTable from "@/components/functional/DisplayTable.vue";
import DirButtonCont from "@/components/functional/DirButtonCont.vue";
import IconClose from "@/components/icons/IconClose.vue";

import resize from "vue-resize-directive";

import { mapGetters, mapState } from "vuex";
import { fs_deleteBike, fs_deleteSpring } from "@/firebaseConfig.js";
import { saveAs } from 'file-saver';


export default {
	name: "LevDB",
	components: {
		DisplayTable,
		ButtonStandard,
		ListSelector,
		ModalWindow,
		IconClose,
		DirButtonCont,
	},

	directives: {
		resize,
	},

	data() {
		return {
			deleteModal: false,

			sortIndex: 1,
			sortDir: 1,

			databaseType: 0, //0 for bike, 1 for spring

			//Local copy of complete lists
			localBikeList: [],
			localSpringList: [],

			//Table setup info
			setup: {
				name: 'bike-db-table',
				liveHeader: true,
				useSelectors: true,
				altColor: true,
				headerState: false,
			},

			dbSelect: 0,
			database: 1,

			deviceWidth: 1000,
			tabletWidth: 850,
			mobileWidth: 600,
		};
	},

	mounted() {
		//For app admin the initial database selected will be private
		if (this.claims.app_admin) {
			this.dbSelect = 1;
		}
	},

	watch: {
		//Update whenever bikes are added/removed from the database
		completeBikeList: {
			immediate: true,
			handler(data) {
				const list = data.map((val) => {
					const item = { ...val }

					item.id = val.id; //Id must be set from prototype

					//Parse times and set order
					item.zDate = this.parseDate(item.dateCreated);
					item.z1Time = this.parseTime(item.dateCreated);
					item.z2Date = this.parseDate(item.dateUpdated);
					item.z3Time = this.parseTime(item.dateUpdated);

					item.sortCreated = this.parseTotalDate(item.dateCreated);
					item.sortUpdated = this.parseTotalDate(item.dateUpdated);

					item.buttonState = false; //Set all initial states to false
					return item;
				})

				//Sort by brand to start
				const sortKey = 'brand';
				list.sort((a, b) => (a[sortKey] > b[sortKey]) ? this.sortDir : -this.sortDir);

				//Create a local copy of the list to allow additial data
				this.localBikeList = list;
			}
		},

		completeSpringList: {
			immediate: true,
			handler(data) {
				const list = data.map((val) => {
					const item = { ...val }
					item.id = val.id; //Id must be set from prototype

					//Parse times and set order
					item.z2Date = this.parseDate(item.dateUpdated);
					item.z3Time = this.parseTime(item.dateUpdated);

					item.sortUpdated = this.parseTotalDate(item.dateUpdated);

					item.buttonState = false; //Set all initial states to false
					return item;
				})

				//Sort by brand to start
				const sortKey = 'brand';
				list.sort((a, b) => (a[sortKey] > b[sortKey]) ? this.sortDir : -this.sortDir);

				//Create a local copy of the list to allow additial data
				this.localSpringList = list;
			}
		}
	},

	computed: {
		...mapGetters('stateViewLev', ['get_fs_database']),
		...mapState('stateViewLev', ['user', 'claims',
			'publicBikeList', 'privateBikeList', 'teamBikeList',
			'publicSpringList', 'privateSpringList', 'teamSpringList',
		]),

		fs_location: function() { return this.get_fs_database(this.database); },

		completeBikeList: function() {
			if (this.database === 0) { return this.publicBikeList; }
			if (this.database === 1) { return this.privateBikeList; }
			if (this.database === 2) { return this.teamBikeList; }

			return this.privateBikeList; //Default. Should not be needed.
		},

		completeSpringList: function() {
			if (this.database === 0) { return this.publicSpringList; }
			if (this.database === 1) { return this.privateSpringList; }
			if (this.database === 2) { return this.teamSpringList; }

			return this.privateSpringList; //Default. Should not be needed.
		},

		//Reduce local list to specific columns
		displayList: function() {
			if (this.databaseType === 0) {
				if (this.deviceWidth < this.mobileWidth) { return this.bikeListMobile.list }
				if (this.deviceWidth < this.tabletWidth) { return this.bikeListTablet.list }
				return this.bikeListFull.list
			}

			if (this.databaseType === 1) {
				if (this.deviceWidth < this.mobileWidth) { return this.springListMobile.list }
				if (this.deviceWidth < this.tabletWidth) { return this.springListTablet.list }
				return this.springListFull.list
			}

			return [];
		},

		//Return correct display headers for bike or spring databse
		displayHeaders: function() {
			if (this.databaseType === 0) {
				if (this.deviceWidth < this.mobileWidth) { return this.bikeListMobile.headers }
				if (this.deviceWidth < this.tabletWidth) { return this.bikeListTablet.headers }
				return this.bikeListFull.headers
			}
			if (this.databaseType === 1) {
				if (this.deviceWidth < this.mobileWidth) { return this.springListMobile.headers }
				if (this.deviceWidth < this.tabletWidth) { return this.springListTablet.headers }
				return this.springListFull.headers
			}

			return [];
		},

		//State dependent single reference to bike or spring lists
		activeList: function() { return this.databaseType === 0 ? this.localBikeList : this.localSpringList; },

		//Array of only list item button states
		buttonStates: function() { return this.activeList.map((item) => { return item.buttonState; }); },

		//Display an no selected message in the delete modal if needed
		noItemsSelected: function() { return this.activeList.findIndex((item) => { return item.buttonState; }); },

		//Database selection options based on user claims
		dbOptions: function() {
			if (this.claims.app_admin) { return ['Public', 'Private', 'Team']; }
			if (this.claims.team_id && this.claims.team_perm > 1) { return ['Private', 'Team']; }
			return ['Private'];
		},

		//Filter list based on device size to better match screen width
		bikeListFull: function() {
			const list = this.localBikeList.map((item) => {
				return {
					author: item.author,
					brand: item.brand,
					modelYear: item.modelYear,
					modelName: item.modelName,
					version: item.version,
					zDate: item.zDate,
					z2Date: item.z2Date,
					z3Time: item.z3Time,
				}
			});

			const headers = ['Author', 'Brand', 'Model Year', 'Model Name',
				'Version', 'Date Created', 'Date Updated', 'Time Updated'
			];

			return { list, headers, }
		},

		bikeListTablet: function() {
			const list = this.localBikeList.map((item) => {
				return {
					author: item.author,
					brand: item.brand,
					modelYear: item.modelYear,
					modelName: item.modelName,
					version: item.version,
					zDate: item.zDate,
				}
			});

			const headers = ['Author', 'Brand', 'Model Year', 'Model Name', 'Version', 'Date Created'];

			return { list, headers, }
		},

		bikeListMobile: function() {
			const list = this.localBikeList.map((item) => {
				return {
					brand: item.brand,
					modelYear: item.modelYear,
					modelName: item.modelName,
					version: item.version,
				}
			});

			const headers = ['Brand', 'Year', 'Name', 'Version'];

			return { list, headers, }
		},

		springListFull: function() {
			const list = this.localSpringList.map((item) => {
				return {
					author: item.author,
					brand: item.brand,
					modelYear: item.modelYear,
					modelName: item.modelName,
					shockLength: item.shockLength,
					shockStroke: item.shockStroke,
					z2Date: item.z2Date,
					z3Time: item.z3Time,
				}
			});

			const headers = ['Author', 'Brand', 'Model Year', 'Model Name',
				'Shock Length', 'Shock Stroke', 'Date Updated', 'Time Updated'
			];

			return { list, headers, }
		},

		springListTablet: function() {
			const list = this.localSpringList.map((item) => {
				return {
					author: item.author,
					brand: item.brand,
					modelYear: item.modelYear,
					modelName: item.modelName,
					shockLength: item.shockLength,
					shockStroke: item.shockStroke,
				}
			});

			const headers = ['Author', 'Brand', 'Model Year', 'Model Name', 'Shock Length', 'Shock Stroke'];

			return { list, headers, }
		},

		springListMobile: function() {
			const list = this.localSpringList.map((item) => {
				return {
					brand: item.brand,
					modelYear: item.modelYear,
					modelName: item.modelName,
					shockLength: item.shockLength,
					shockStroke: item.shockStroke,
				}
			});

			const headers = ['Brand', 'Year', 'Name', 'Length', 'Stroke'];

			return { list, headers, }
		},
	},

	methods: {
		onResize: function(val) { this.deviceWidth = val.clientWidth; },

		parseTotalDate: function(date) {
			return new Date(date.seconds * 10 ** 3 + date.nanoseconds / 10 ** 6);
		},

		parseDate: function(date) {
			let parsedDate;
			try {
				parsedDate = new Date(date.seconds * 10 ** 3 + date.nanoseconds / 10 ** 6);
				parsedDate = new Intl.DateTimeFormat('en-CA').format(parsedDate);
			} catch {
				parsedDate = new Date(date);
				parsedDate = new Intl.DateTimeFormat('en-CA').format(parsedDate);
			}

			return parsedDate;
		},

		parseTime: function(date) {
			date = new Date(date.seconds * 10 ** 3 + date.nanoseconds / 10 ** 6);
			const time = date.toLocaleTimeString('en-gb')
			return time;
		},

		//Sort lists by selected header
		headerSort: function(index) {
			if (this.activeList.length < 1) { return; }

			if (this.sortIndex === index) {
				this.sortDir = -this.sortDir;
			} else {
				this.sortDir = 1;
				this.sortIndex = index;
			}

			//Keys must be obtained from the display list options
			let sortKey = Object.keys(this.displayList[0])[this.sortIndex];

			//Include full timestamp for date/time sorts
			if (sortKey === 'zDate' || sortKey === 'z1Time') { sortKey = 'sortCreated'; }
			if (sortKey === 'z2Date' || sortKey === 'z3Time') { sortKey = 'sortUpdated'; }

			//Local bike list must be used for sorting to hold onto state and id
			this.activeList.sort(
				(a, b) => (a[sortKey] > b[sortKey]) ? this.sortDir : -this.sortDir
			);

			//Reset button state on sorting
			this.activeList.forEach((item) => { item.buttonState = false; });
		},

		//When row is selected set variable
		selectClick: function(index) {
			this.activeList[index].buttonState = !this.activeList[index].buttonState;
		},

		//Select or deselect all
		selectAllNone: function() {
			//Set header state check
			this.$set(this.setup, 'headerState', !this.setup.headerState)

			//Set all list items to same clicked state
			this.activeList.forEach((item) => {
				this.$set(item, 'buttonState', this.setup.headerState);
			});
		},

		//Delete each selected bike
		deleteBike: async function() {
			//Populate local select list
			const deleteIndex = [];
			//Get each required delete index
			this.activeList.forEach((item, index) => {
				if (this.buttonStates[index]) { deleteIndex.push(item.id); }
			});

			//Delete each bike in list
			deleteIndex.forEach(async (item) => {
				try {
					if (this.databaseType === 0) {
						await fs_deleteBike(item, this.fs_location);
						console.log('Deleted Bike: ' + item);
					}

					if (this.databaseType === 1) {
						await fs_deleteSpring(item, this.fs_location);
						console.log('Deleted Spring: ' + item);
					}
				} catch (error) {
					console.log(error)
				}
			});

			//Close delete modal
			this.deleteModal = false;
		},

		//Handle modal open/close
		setDeleteModal: function() { this.deleteModal = !this.deleteModal },

		//Remove selected item from delete modal
		removeDeleteItem: function(id) {
			this.activeList.forEach((item) => {
				if (item.id === id) {
					this.$set(item, 'buttonState', false);
				}
			});
		},

		//Toggle from bike to spring database
		toggleDB: function() { this.databaseType = this.databaseType === 0 ? 1 : 0; },

		//Text to show for each selected delete item. 
		deleteText: function(item) {
			if (this.databaseType === 0) {
				return item.modelYear + ' ' + item.brand + ' ' + item.modelName + ' v' + item.version;
			}

			if (this.databaseType === 1) {
				return item.modelYear + ' ' + item.brand + ' ' + item.modelName +
					' ' + item.shockLength + 'x' + item.shockStroke;
			}

			return '';
		},

		changeDatabase: function() {
			//Handles the admin user case where public must be shown as an option
			if (this.dbOptions.length < 3) {
				this.database = this.dbSelect + 1;
			} else {
				this.database = this.dbSelect;
			}
		},

		downloadDB: function() {
			const blob = new Blob([JSON.stringify(this.localBikeList)], { type: 'application/json' });
			saveAs(blob, "syn-bike-db.json");
		},
	},
};
</script>
<style>
.db-table-cont {
	width: 100rem;
	max-width: 100rem;
	display: flex;
	padding: 3rem;
	margin: 2rem;
	overflow: hidden;
}

.list-delete-bikes {
	display: flex;
	justify-content: space-between;
	margin: 1.5rem 1rem;
	font-size: 1.5rem;
	height: 2rem;
	line-height: 2rem;
	/*border-bottom: .1rem solid var(--color-bg-secondary-light)*/
}

.cont-bike-delete-list {
	max-width: 100%;
	max-height: 25rem;
	margin-bottom: 1rem;
	overflow: hidden;
	overflow-y: auto;
}

.cancel-bike-icon {
	/*display: flex;*/
	width: 1.8rem;
	min-width: 1.8rem;
	height: 1.8rem;
	min-height: 1.8rem;
	padding: .1rem .1rem .2rem .1rem;
	border-radius: .3rem;
	/*background: transparent;*/
	fill: var(--color-font-bg);
}

.list-delete-bikes__text {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.db-control-cont {
	max-width: 70rem;
	min-width: 42rem;
	margin: 2rem auto;
}


@media(max-width: 500px) {
	.cont-bike-delete-list {
		max-height: none;
	}

	.db-table-cont {
		width: 100%;
		display: flex;
		padding: 1rem 1rem;
		margin: 0;
		overflow: hidden;

		box-shadow: none !important;

		font-size: 1.2rem;
	}

	.db-control-cont {
		margin: 2rem 0;
		width: 100%;
		min-width: 0;
	}

}
</style>
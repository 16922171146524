import Vue from "vue"
import Vuex from "vuex"

import { vuexfireMutations } from 'vuexfire'

import stateOptimize from "./modules/stateOptimize"
import stateViewLev from "./modules/stateViewLev"
import stateBikeData from "./modules/stateBikeData"

Vue.use(Vuex);

export default new Vuex.Store({
	mutations: {
		...vuexfireMutations, //Required in store root
	},

	modules: {
		stateViewLev,
		stateBikeData,
		stateOptimize,
	},
});
export class DefaultSettings {
	constructor() {
		this.springUnits = 0;
		this.screenStyle = 0;
		this.liveUpdate = 1;

		//Options below must have an associated name in the settings panel
		this.infoPanel = {
			wheelTravel: true,
			shockTravel: true,

			leverageStart: true,
			leveragePos: true,
			leverageEnd: true,
			typeLeverage: 0,

			asStart: false,
			asSag: true,
			asPos: true,
			asEnd: false,
			typeAntisquat: 0,

			arStart: false,
			arSag: true,
			arPos: true,
			arEnd: false,
			typeAntirise: 0,

			chainGrowthTangent: false,
			chainGrowthTotal: true,
			pedalKickbackPos: false,
			pedalKickbackEnd: true,

			progression: true,
			energy: false,

			axlepathPos: false,
			axlepathMax: true,
			axlePathEnd: false,

			wheelForceStart: false,
			wheelForcePos: false,
			wheelForceEnd: false,

			wheelRateStart: false,
			wheelRatePos: false,
			wheelRateEnd: false,

			showDividers: true,
		};

	}
}
<template>
	<div class="message-list">
		<div v-for="(item, index) in msgListLocational" class="message-list__line flex-column" :key="'server-message-' + index">
			<div>{{item.msg}}</div>
			<div class="flex-row">
				<button class="message-list__msg-btn" @click="resolveMsg(item, 'accept', index)">Accept</button>
				<button class="message-list__msg-btn" @click="resolveMsg(item, 'decline', index)">Decline</button>
				<div class="message-list__msg-icon">
					<IconValidate :valid="messageState === 1 && index === messageIndex" :loading="isResolvingMsg && index === messageIndex" :invalid="messageState === 2 && index === messageIndex" />
				</div>
			</div>
		</div>
		<div v-if="msgListLocational.length < 1" class="message-list__line">No messages to display.</div>
	</div>
</template>
<script>
import IconValidate from "@/components/icons/IconValidate.vue";
import { axiosIPC } from "@/modules/sendRequest.js";
import { auth, fs_remove_message } from "@/firebaseConfig.js";
import { mapActions, mapState } from 'vuex';

export default {
	name: 'MessageList',

	components: {
		IconValidate,
	},

	data() {
		return {
			isResolvingMsg: false,
			messageResolved: false,
			messageIndex: -1,
			messageState: 0,
		}
	},

	computed: {
		...mapState('stateViewLev', ['errorMessages', 'messageList']),
		...mapState('stateBikeData', ['maxNumberBikes', 'bikeDataArray']),

		//Sent bike will only show when main app is open
		msgListLocational: function() {
			if (this.$route.name.toLowerCase() === 'lev') { return this.messageList; }

			return this.messageList.filter((msg) => {
				return msg.type !== 'send_bike'
			});
		}
	},

	methods: {
		...mapActions('stateViewLev', ['setError']),
		...mapActions('stateBikeData', ['loadDBBike']),

		//Handle message resolution
		resolveMsg: async function(msg, resolveState, index) {
			this.messageIndex = index;

			let url = resolveState === 'decline' ? msg.declineURL : msg.acceptURL;
			let returnData = resolveState === 'decline' ? msg.decline_data : msg.accept_data;

			if (msg.type === 'send_bike' && resolveState === 'accept') {
				this.acceptBike(msg)
				return;
			}

			this.messageState = 0;
			this.isResolvingMsg = true;
			const res = await this.sendReqest(returnData, url)
			if ('error' in res) {
				this.isResolvingMsg = false;
				this.messageState = 2;
				this.setError(res.error.message);
				this.$nextTick(() => { clearTimeout(this.errorTimeout) });
				return;
			}

			if (resolveState === 'decline') { fs_remove_message(msg.id); }

			this.isResolvingMsg = false;
		},

		//Handle axios messages
		sendReqest: async function(data, url) {
			const idToken = await auth.currentUser.getIdToken();
			const authToken = `Bearer ${idToken}`;

			const requestOptions = {
				method: 'POST',
				url: url,
				data: data,
				headers: {
					'Authorization': authToken,
					'Content-Type': 'application/json'
				},
				timeout: 7000,
			};

			let isError = false;
			const res = await axiosIPC(requestOptions).catch(() => { isError = true; });
			if (isError) {
				return {
					error: {
						type: 'connection-error',
						message: 'Issues contacting server. Please check your connection and try again.',
					}
				}
			}
			return res.data;
		},

		acceptBike: function(msg) {
			if (this.bikeDataArray.length >= this.maxNumberBikes) {
				this.setError('Too many bikes loaded. Please close some bikes and try again.');
				return;
			}

			const bike = JSON.parse(msg.accept_data);
			bike.database = 1;
			bike.modelName = '\uD83D\uDD83 ' + bike.modelName;
			this.loadDBBike(bike);
			fs_remove_message(msg.id);
		},
	},
}
</script>
<style>
.message-list {
	background: transparent;
	width: 100%;
}

.message-list__line {
	width: 100%;
	display: flex;
	justify-content: space-between;

	background-color: transparent;
	padding: .7rem;
	border-bottom: .1rem solid var(--color-bg-secondary-light);

	text-align: left;
}

.message-list__msg-btn {
	width: 7rem;
	height: 3rem;
	margin-top: 1rem;

	border: none;
	border: .1rem solid var(--color-font-bg);
	border-radius: .3rem;
	background: transparent;
	color: var(--color-font-bg);
	cursor: pointer;

	transition: .2s;
}

.message-list__msg-icon {
	width: 3rem;
	height: 3rem;

	margin: 0 .5rem;
	margin-top: 1rem;
	background: transparent;
	stroke: var(--color-font-bg);
}

.message-list__msg-btn:hover,
.message-list__msg-btn:focus {
	box-shadow: 0 0 0 3px var(--color-bg-highlight);
}

.message-list__msg-btn:active {
	box-shadow: none;
}

.message-list__msg-btn:first-child {
	margin-right: 1rem;
}
</style>
'use strict';

import { DefaultBike } from "@/modules/defaultBike.js";
import { DefaultSettings } from "@/modules/defaultSettings.js";
import { copy } from "@/modules/utility.js";

export function validateBikeObj(bikeObj) {
	const bikeTemplate = new DefaultBike();

	for (let key in bikeTemplate) {
		if (!(key in bikeObj)) { bikeObj[key] = copy(bikeTemplate[key]) }
	}

	//Check for new parameters and set new params equal to bikeTemplate if needed:
	for (let key in bikeTemplate) {
		//If the key is an object (and not a primitive)
		if (typeof(bikeTemplate[key]) === 'object') {
			//Check that the object has all the same subproperties as the bikeTemplate template
			for (let subKey in bikeTemplate[key]) {
				if (!(subKey in bikeObj[key])) {
					//Geometry must be handled uniquely to handle per-size arrays
					if (key === 'geometry') {
						const defaultVal = copy(bikeTemplate[key][subKey]);
						bikeObj[key][subKey] = bikeObj.geometry.reach.map(() => defaultVal[0]);
					} else {
						bikeObj[key][subKey] = copy(bikeTemplate[key][subKey]);
					}
				}
			}
			//Remove legacy properties from bikeObj
			for (let subKey in bikeObj[key]) {
				if (!(subKey in bikeTemplate[key])) {
					delete bikeObj[key][subKey];
				}
			}
		}
	}

	return bikeObj;
}

export function validateSettings(settings) {
	const settingsTemplate = new DefaultSettings();

	for (let key in settingsTemplate) {
		if (!(key in settings)) { settings[key] = copy(settingsTemplate[key]) }

		//Check that the object has all the same subproperties as the settingsTemplate template
		if (typeof(settingsTemplate[key]) === 'object') {
			for (let subKey in settingsTemplate[key]) {
				if (!(subKey in settings[key])) { settings[key][subKey] = copy(settingsTemplate[key][subKey]); }
			}
			for (let subKey in settings[key]) {
				if (!(subKey in settingsTemplate[key])) { delete settings[key][subKey]; }
			}
		}
	}

	return settings;
}

// function arrayEquals(a, b) {
// 	return Array.isArray(a) &&
// 		Array.isArray(b) &&
// 		a.length === b.length &&
// 		a.every((val, index) => val === b[index]);
// }
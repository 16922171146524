console.log('V1.1.14')
import Vue from 'vue';
import App from '@/App.target.vue';
import router from '@/router';
import store from '@/store';

import VueKonva from 'vue-konva';

import browserDetect from 'vue-browser-detect-plugin';
import LoadScript from 'vue-plugin-load-script';
import vClickOutside from 'v-click-outside';
import {firestorePlugin} from 'vuefire';
import {VTooltip} from 'v-tooltip';

import {db, auth, fs_cred_login} from '@/firebaseConfig.js';
import {openFile, saveFile} from '@/modules_electron/fileEvents.js';

import '@/assets/css/normalize.css';
import '@/assets/css/typography.css';
import '@/assets/css/style.css';
import '@/assets/css/tooltip.css';
import '@/assets/css/component-style.css';
import '@/assets/css/vue-transitions.css';

import axios from 'axios';

Vue.config.productionTip = false;
Vue.use(VueKonva);
Vue.use(firestorePlugin);
Vue.use(LoadScript);
Vue.use(vClickOutside);
Vue.use(browserDetect);

// Setup Tooltip defaults
Vue.directive('tooltip', VTooltip);
VTooltip.options.defaultDelay = 300;
VTooltip.options.defaultHideOnTargetClick = false;
VTooltip.options.html = false;

Vue.prototype.$version = '0.0.2';
Vue.prototype.$colorBackground = '#FFFFFF';
Vue.prototype.$colorPrimary = '#720793';

let userListener = () => {};  // Snapshot listener initalization

const debug = false;
const app =
    new Vue({
      router,
      store,
      created() {
        // Monitor window width for mobile/not mobile
        if (process.env.IS_ELECTRON) {
          setupElectron();
        }

        window.addEventListener('resize', () => {
          store.dispatch(
              'stateViewLev/setShort',
              ['isMobile', window.innerWidth <= 500 ? true : false]);
        });
        window.dispatchEvent(new Event('resize'));  // Set initial state

        // Manage authentication state changes
        auth.onAuthStateChanged(async (user) => {
          if (debug) {
            console.log('Auth Change');
          }
          userListener();  // Remove user listener on any auth change
          if (user) {
            window?.umami?.track?.('login', {
              email: user.email,
            })

            // Reset store on user changeover (without intermediate logout)
            if (store.state.stateViewLev.user !== null) {
              if (store.state.stateViewLev.user.uid !== user.uid) {
                store.dispatch('stateBikeData/resetState');
                store.dispatch('stateViewLev/resetState');
                store.dispatch('stateOptimize/resetState');
              }
            }

            // Get sizing after store reset
            window.dispatchEvent(new Event('resize'))  // Set initial state

            let claims = {};
            try {
              const tokenRes = await user.getIdTokenResult(true);
              claims = tokenRes.claims;
            } catch {
              claims = {error: true};
            }

            store.dispatch('stateViewLev/setShort', ['user', user]);
            store.dispatch('stateViewLev/setShort', ['claims', claims]);
            store.dispatch(
                'stateViewLev/syncStripeInfo');  // Sync non-auth Firestore
            store.dispatch('stateViewLev/syncUserData');  // Sync user info and
                                                          // object databases

            // Listen to user data. This will allow APIs to trigger update when
            // claims are 	changed. Account changes can then be reconized when
            //webhooks are triggered
            userListener = db.collection('user-data')
                               .doc(user.uid)
                               .onSnapshot(userDataListener);

          } else {
            // Reset stores on logout
            store.dispatch('stateBikeData/resetState');
            store.dispatch('stateViewLev/resetState');
            store.dispatch('stateOptimize/resetState');

            // Get sizing after store reset
            window.dispatchEvent(new Event('resize'));  // Set initial state

            // Remove user from authenticated areas
            if (this.$router.currentRoute.path !== '/') {
              this.$router.push('/');
            }
          }
        });
      },
      render: h => h(App)
    }).$mount('#app');

if (window.Cypress) {
  // only available during E2E tests
  window.app = app
}

const userDataListener =
    async () => {
  let isError = false;
  if (debug) {
    console.log('UserInfo update');
  }
  const existingTeam = store.state.stateViewLev.claims.team_id;

  const active_user = auth.currentUser;

  const tokenRes = await active_user.getIdTokenResult(true).catch(() => {
    isError = true;
  })

  if (isError) {
    if (debug) {
      console.log('User Listener Error');
    }
    if (process.env.IS_ELECTRON) {
      const data = await window.ipcRenderer.invoke('offline-user');
      store.dispatch('stateViewLev/setShort', ['user', data.user]);
      store.dispatch('stateViewLev/setShort', ['userInfo', data.userInfo]);
      store.dispatch('stateViewLev/setShort', ['claims', data.claims]);
    } else {
      store.dispatch('stateViewLev/setError', 'Network connection problems');
    }
    return;
  }

  let claims = tokenRes.claims;
  // Save updated user and claims to store.
  store.dispatch('stateViewLev/setShort', ['user', active_user]);
  store.dispatch('stateViewLev/setShort', ['claims', claims]);

  // Check if resyncing team firestore is needed
  if (claims.team_id !== existingTeam) {
    store.dispatch('stateViewLev/syncUserData');
  }

  // Move to next route if desired
  if (router.currentRoute.query.from) {
    router.push({path: router.currentRoute.query.from})
        .catch(() => console.log('Insufficient Permissions'));
  }
}

//----------------------------------------------------------------------------
// SET ELECTRON INTERFACING----------------------------------------------------
async function
setOnlineStatus() {
  const status = await window.ipcRenderer.invoke('is-online');
  store.dispatch('stateViewLev/setShort', ['isOnline', status]);
  // It appears firestore will await network and restore connection as needed
  // if (store.state.stateViewLev.isOnline === false && status) {}
}

async function
setupElectron() {
  await setOnlineStatus()
  setInterval(setOnlineStatus, 10000);  // Ping to determine if network
                                        // available
  window.ipcRenderer.on('logout', async () => {
    await auth.signOut();
    if (router.currentRoute.path !== '/') {
      router.push('/');
    }
    window.ipcRenderer.invoke('set-store', [
      'user',
      {
        user: null,
        userInfo: {},
        claims: {},
      },
    ]);
  });

  window.ipcRenderer.on('close-save-user', async () => {
    await window.ipcRenderer.invoke('close', {
      user: JSON.stringify(store.state.stateViewLev.user),
      userInfo: store.state.stateViewLev.userInfo,
      claims: store.state.stateViewLev.claims,
    });
  });

  window.ipcRenderer.on('open-bike', openFile);
  window.ipcRenderer.on('save-bike', saveFile);
  window.ipcRenderer.on('login-cred', fs_cred_login);
  window.ipcRenderer.on('main-console', mainConsole);
}

function mainConsole(event, msg) {
  console.log(msg);
}

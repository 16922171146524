import Vue from "vue";
import axios from "axios";
import { perf, auth } from "@/firebaseConfig.js";

//Custom modules
import { magnitude, geoForkRise, geoForkRun, geoTrail, geoSeatedFit, geoHTT } from "@/modules/computeGeo.js";
import { simpleDeriv } from "@/modules/fornbergDerivative.js";
import { theta_x, computeModLinkage } from "@/modules/computeLinkage.js";
import { DefaultBike, BikeView } from "@/modules/defaultBike.js";
import { validateBikeObj } from "@/modules/validateFile.js";
import { copy, uuid } from "@/modules/utility.js";

const debug = false;
const debugUndoRedo = false;

const getDefaultState = () => {
	return {
		maxNumberBikes: 20,
		maxNumberSizes: 10, //Must match default spring arrays to this
		selectedBikeIndex: -1,
		solverURL: '',

		bikeDataArray: [], //Holds bike object data 

		bikeViewArray: [], //Holds plot data including result arrays

		//BIKE DISPLAY DATA--------------
		currentColorIndex: 0, //Pan through available colors as bikes are added and deleted
		colors: ['#EA2027', '#006266', '#1B1464', '#5758BB', '#6F1E51',
			'#F79F1F', '#A3CB38', '#1289A7', '#D980FA', '#B53471',
			'#EE5A24', '#009432', '#0652DD', '#9980FA', '#833471',
			'#FFC312', '#C4E538', '#12CBC4', '#FDA7DF', '#ED4C67',
		],

		//Point names for displaying in UI
		pointNames: ['BB', 'R. Wheel', 'Shock 1', 'Shock 2',
			'Frame - L1', 'L1 - L2', 'L2 - L3', 'L3 - Frame',
			'L4 Start', 'L4 - L5', 'L5 Start', 'Idler', 'Brake 1',
			'Brake 2', 'DAQ1', 'DAQ2', 'P16', 'P17', 'COG', 'F. Wheel',
		],
	}
};

const getters = {
	getData: (state) => (item, subItem = '') => {
		if (subItem === '') { return state[item]; } else {
			return state[item][subItem]
		}
	},
	getBikeData: (state) => (index, item = '', subItem = '') => {
		if (state.bikeDataArray[index] !== undefined) {
			if (item === '') {
				return state.bikeDataArray[index];
			} else if (subItem === '') {
				return state.bikeDataArray[index][item];
			} else {
				return state.bikeDataArray[index][item][subItem];
			}
		}
	}
};

const state = getDefaultState();

const mutations = {
	RESETSTATE(state) { Object.assign(state, getDefaultState()); },

	//Put bike into store and initialize view data
	PUTBIKE: (state, payload) => {
		state.bikeDataArray.push(payload.newBike);
		state.bikeViewArray.push(payload.viewState);
		state.selectedBikeIndex = state.bikeDataArray.length - 1;

		state.currentColorIndex++;
		if (state.currentColorIndex === state.colors.length) { state.currentColorIndex = 0; }

		//Get derivative geo numbers
		checkGeoChanges(state.selectedBikeIndex);
		computeActiveFlipChip(state.selectedBikeIndex);
	},

	//Remove bike from store
	DELETEBIKE: (state, payload) => {
		if (debug) { console.log('Delete bike ' + payload.index); }

		if (payload.index === state.selectedBikeIndex) {
			state.selectedBikeIndex = 0; //If the currently selected bike is being deleted then reset
		} else if (payload.index < state.selectedBikeIndex) {
			state.selectedBikeIndex--; //If deleting bike below selected one correct index
		}

		state.bikeDataArray.splice(payload.index, 1);
		state.bikeViewArray.splice(payload.index, 1);
	},

	//Put basic data into store
	PUTDATA: (state, payload) => {
		if (debug) {
			console.log('putData: payload is ' + payload.item);
			console.log('item is ' + payload.value);
		}
		state[payload.item] = payload.value;
	},

	//Put data into bike view state in bikeViewArray array
	PUTBIKEVIEWDATA: (state, payload) => {
		if (debug) { console.log(payload); }
		if ('subItem' in payload && payload.subItem !== '') {
			Vue.set(state.bikeViewArray[payload.index][payload.item], payload.subItem, payload.value);
		} else {
			Vue.set(state.bikeViewArray[payload.index], payload.item, payload.value);
		}

		applySpringForce(payload.index);
	},

	//Put data into bike object in bikeDataArray
	PUTBIKEDATA: (state, payload) => {
		if (debug) {
			console.log('putBikeData: payload is ' + payload.item + ' ' + payload.subItem);
			console.log(payload.value)
		}
		//Optional index selection for setting parameter on non-selected bike
		if (!('index' in payload)) { payload.index = state.selectedBikeIndex; }

		let previousValue = '';
		if ('subItem' in payload && payload.subItem !== '') {
			previousValue = state.bikeDataArray[payload.index][payload.item][payload.subItem];
			Vue.set(state.bikeDataArray[payload.index][payload.item], payload.subItem, payload.value);
		} else {
			previousValue = state.bikeDataArray[payload.index][payload.item];
			Vue.set(state.bikeDataArray[payload.index], payload.item, payload.value);
		}

		//Handle undo/redo records. Set for all changes unless explicity disabled
		if (!payload.noRecord) { createRecord(payload, previousValue, 'PUTBIKEDATA'); }

		//For drivetrain, change in size selected, or geo array updates recompute geo for wheel size
		if (payload.item === 'drivetrainInfo' ||
			payload.item === 'selectedSize' ||
			payload.item === 'geometry' ||
			payload.subItem === 'shockLengthFixed' ||
			payload.subItem === 'shockLength' ||
			payload.subItem === 'shockExtLength') {
			checkGeoChanges(payload.index);
			applySpringForce(payload.index);
		}

		computeActiveFlipChip(payload.index);
	},

	//Update bike point data or geometry
	PUTBIKEPOINTDATA: (state, payload) => {
		if (debug) {
			console.log('putBikePointData: payload is ' + payload.item);
			console.log('item is ' + payload.value);
		}

		//Optional index selection for setting parameter on non-selected bike
		if (!('index' in payload)) { payload.index = state.selectedBikeIndex; }
		let previousValue = '';
		//Option for non-reactive set to improve performance in select situations (ex. Konva Plotting)
		if ('notReactive' in payload) {
			previousValue = state.bikeDataArray[payload.index][payload.item][payload.row][payload.column];
			state.bikeDataArray[payload.index][payload.item][payload.row][payload.column] = payload.value;
		} else {
			previousValue = state.bikeDataArray[payload.index][payload.item][payload.row][payload.column];
			Vue.set(state.bikeDataArray[payload.index][payload.item][payload.row], payload.column, payload.value);
			//Only update geo for shock points or geo and when reactive set is used
			if (payload.row === 2 || payload.row === 3) {
				checkGeoChanges(payload.index);
			}
			if (payload.item === 'geometry') {
				checkGeoChanges(payload.index, payload.column); //Geometry settings will provide option size parameter
				applySpringForce(payload.index);
			}
			computeActiveFlipChip(payload.index);
		}

		//Handle undo/redo records
		if (!payload.noRecord) { createRecord(payload, previousValue, 'PUTBIKEPOINTDATA'); }
	},

	//Update flip chip data for a bike. 
	PUTCHIPDATA: (state, payload) => {
		if (debug) {
			console.log('putBikePointData: payload is ' + payload.item);
			console.log('item is ' + payload.value);
		}

		if (!('index' in payload)) { payload.index = state.selectedBikeIndex; }

		let previousValue = '';
		const chip = state.bikeDataArray[payload.index].solverInfo.chipConfigs[payload.chipIndex]

		//Each chip object can contain top level parameters and points in primary and secondary arrays
		//Payload will contain an item key and a value
		//Points will also contain a point number and whether it is an x or y value (x - 0, y - 1)
		if (!('point' in payload)) {
			previousValue = chip[payload.item]
			Vue.set(chip, payload.item, payload.value)
		} else {
			previousValue = chip[payload.item][payload.point][payload.x_y]
			Vue.set(chip[payload.item][payload.point], payload.x_y, payload.value)
		}

		//Handle undo/redo records
		if (!payload.noRecord) { createRecord(payload, previousValue, 'PUTCHIPDATA'); }
		computeActiveFlipChip(payload.index)
	},

	UNDO: (state, index) => {
		state.bikeViewArray[index].changeIndex -= 1;
		if (debugUndoRedo) { console.log(state.bikeViewArray[index].changeIndex); }
	},

	REDO: (state, index) => {
		state.bikeViewArray[index].changeIndex += 1;
		if (debugUndoRedo) { console.log(state.bikeViewArray[index].changeIndex); }
	},

};

const actions = {
	resetState: ({ commit }) => { commit('RESETSTATE'); },

	undo: ({ commit, dispatch }) => {
		const index = state.selectedBikeIndex;
		if (state.bikeDataArray.length < 1) { return; }
		if (state.bikeViewArray[index].changeIndex < 0) { return; }

		const record = state.bikeViewArray[index].changeRecord[state.bikeViewArray[index].changeIndex];

		record.payload.index = index; //Always reset to correct index in case bikes have moved
		record.payload.value = record.payload.previousValue; //For undo we use previous val
		commit(record.mutation, record.payload);
		commit('UNDO', index);
		dispatch('sendAndSolve');
	},

	redo: ({ commit, dispatch }) => {
		const index = state.selectedBikeIndex;

		if (state.bikeDataArray.length < 1) { return; }
		if (state.bikeViewArray[index].changeIndex >= state.bikeViewArray[index].changeRecord.length - 1) { return; }

		const record = state.bikeViewArray[index].changeRecord[state.bikeViewArray[index].changeIndex + 1];

		record.payload.index = index; //Always reset to correct index in case bikes have moved
		record.payload.value = record.payload.currentValue; //For redo we use original val
		commit(record.mutation, record.payload);
		commit('REDO', index);
		dispatch('sendAndSolve');
	},

	loadNewBike: ({ commit, state }) => {
		const newBike = new DefaultBike();
		const viewState = new BikeView(state.colors[state.currentColorIndex]);

		const currentDate = new Date();
		newBike.modelYear = currentDate.getFullYear().toString();
		newBike.dateCreated = currentDate.toJSON();
		newBike.dateUpdated = currentDate.toJSON();

		const payload = {
			newBike,
			viewState,
		};
		commit('PUTBIKE', payload);
	},

	loadDBBike: ({ commit, state, dispatch }, bikeObj) => {
		const viewState = new BikeView(state.colors[state.currentColorIndex]); //Initialize view
		const checkedBike = validateBikeObj(bikeObj); //Validate bike object
		checkedBike.solverInfo.selectedChip = 0; //Reset chip selection to default
		commit('PUTBIKE', {
			newBike: checkedBike,
			viewState: viewState,
		});
		dispatch('sendAndSolve');
	},

	deleteBike: ({ commit }, index) => {
		commit('DELETEBIKE', { index });
	},

	setData: ({ commit }, payload) => {
		commit(payload.commit, payload);
	},

	sendAndSolve: async ({ commit, state }) => {
		const index = state.selectedBikeIndex;

		if (state.bikeDataArray.length > 0) {
			let solveResult = {}; //Object for returning errors if needed

			const trace = perf.trace('gcf_solver');
			trace.start();
			let time = performance.now();

			//Use the start time as a hash to find the bike object later.This prevents the data from
			//	 being written to the incorrect bike if the arrays change during the solving time delay.
			const objRef = uuid();
			commit('PUTBIKEDATA', {
				index: index,
				item: 'solverInfo',
				subItem: 'solveRef',
				value: objRef,
				noRecord: true,
			});

			//Copy bike and move chip solution into main parameters
			const bike = copy(state.bikeDataArray[index]);
			bike.geometry = bike.solverInfo.chipSolution.geometry;
			bike.points = bike.solverInfo.chipSolution.solvePoints;
			bike.solverInfo.shockStroke = bike.solverInfo.chipSolution.shockStroke;
			bike.drivetrainInfo = bike.solverInfo.chipSolution.drivetrainInfo

			//Setup API Call-------------------------------------------------
			const requestOptions = {
				method: 'POST',
				url: state.solverURL,
				data: bike,
				headers: {
					// 'Authorization': authToken,
					'Content-Type': 'application/json',
				},
				timeout: 7000,
			};

			let response = {};
			//Electron uses internal solver. Otherwise use API
			if (process.env.IS_ELECTRON) {
				const ipcResponse = await window.ipcRenderer.invoke('solve-local', requestOptions);
				response = ipcResponse.response;
				solveResult = ipcResponse.solveResult;
			} else {
				const idToken = await auth.currentUser.getIdToken();
				const authToken = `Bearer ${idToken}`;
				requestOptions.headers['Authorization'] = authToken;

				response = await axios(requestOptions).catch(err => {
					if (err.response) {
						solveResult.status = err.response.status;
						solveResult.error = 'Network error. Please check your connection and try again.';
					} else if (err.request) {
						solveResult.status = 500;
						solveResult.error = 'Network error. Please check your connection and try again.';
					} else {
						solveResult.status = 500;
						solveResult.error = 'Network error. Please check your connection and try again.';
					}
				});
			}

			//Remove data from view for bad request
			if (solveResult.status && solveResult.status === 400) {
				commit('PUTBIKEVIEWDATA', {
					index: index,
					item: 'kinematicData',
					value: {},
				});
			}

			//Return early for error. If data is string JSON parse failed
			if ('error' in solveResult || typeof response.data === 'string') { return solveResult; }
			if (debug) { console.log(typeof response.data); }

			// Initialize wheel arrays
			response.data.wheel_force = new Array(response.data.leverage.length).fill(0);
			response.data.wheel_rate = new Array(response.data.leverage.length).fill(0);
			response.data.wheel_prog = new Array(response.data.leverage.length).fill(0);
			response.data.energy = new Array(response.data.leverage.length).fill(0);
			const lev_keys = ['leverage', 'leverage_h', 'leverage_bump'];

			lev_keys.forEach(key => {
				response.data[key].forEach((item, idx) => {
					if (item > 10) { response.data[key][idx] = 10; }
					if (item < -10) { response.data[key][idx] = -10; }
				});
			});

			response.data.leverage_diff = response.data['leverage'].map((item, idx) => {
				return item - response.data['leverage_bump'][idx]
			});

			//Check that there is ref match and solve. This will also only load the most recent solve request.
			const currentIdx = state.bikeDataArray.findIndex(bike => bike.solverInfo.solveRef === objRef);
			if (currentIdx > -1) {
				let payload = {
					index: currentIdx,
					item: 'kinematicData',
					value: response.data,
				}
				commit('PUTBIKEVIEWDATA', payload);
			} else {
				console.log('Toss Result')
			}

			trace.stop();
			console.log('solver response time is: ' + (performance.now() - time));
			return solveResult;
		}
	},
};

function checkGeoChanges(bikeIndex, size = false) {
	if (debug) { console.log('calculating geo'); }
	const bike = state.bikeDataArray[bikeIndex];
	const geo = bike.geometry;

	//Size is overidden when setting a geometry item directly. See PUTBIKEPOINTDATA
	size = size || size === 0 ? size : state.bikeDataArray[state.selectedBikeIndex].selectedSize;

	//Handle Fixed Shock length (Shock fixed at one end and opposite moves in circle)
	if (bike.solverInfo.shockLengthFixed) {
		//If the points overlap then move to prevent NaN value
		if ((Math.abs(bike.points[3][0] - bike.points[2][0]) +
				Math.abs(bike.points[3][1] - bike.points[2][1])) < .01) {
			if (bike.solverInfo.shockLength < .1) { bike.solverInfo.shockLength = 1; }
			bike.points[3][0] = bike.solverInfo.shockLength;
			bike.solverInfo.shockExtLength = 0;
		}
		const shockTheta = theta_x(bike.points[3], bike.points[2]);
		const x_shock = Math.cos(shockTheta) * (bike.solverInfo.shockLength + bike.solverInfo.shockExtLength) + bike.points[3][0];
		const y_shock = Math.sin(shockTheta) * (bike.solverInfo.shockLength + bike.solverInfo.shockExtLength) + bike.points[3][1];
		Vue.set(bike['points'], 2, [x_shock, y_shock]);
	} else {
		const extLength = magnitude(bike.points[2], bike.points[3]) - bike.solverInfo.shockLength;
		Vue.set(bike['solverInfo'], 'shockExtLength', Math.round(100 * extLength) / 100);
	}

	//Calculate wheel positions from geo and load point data structure
	Vue.set(bike['points'], 1, [-geo.rc[size] + geo.bbOffset[size], geo.bbDrop[size]]);
	Vue.set(bike['points'], 0, [geo.bbOffset[size], 0]);
	Vue.set(bike['geometry']['cs'], size, magnitude(bike.points[0], bike.points[1]));
	Vue.set(bike['geometry']['bbHeight'], size, bike.drivetrainInfo.tireDiameter / 2 - bike.geometry.bbDrop[size])

	//Calculate Stack
	const stack = geoForkRise(geo.forkLength[size] + geo.lowerHeadset[size] + geo.htl[size], geo.offset[size],
		geo.hta[size]) + (bike.drivetrainInfo.tireDiameter_front - bike.drivetrainInfo.tireDiameter) / 2 + geo.bbDrop[size];
	Vue.set(bike['geometry']['stack'], size, stack);

	//Calculate Seated Fit
	const seatedFit = geoSeatedFit(geo.reach[size], geo.stack[size], geo.hta[size], geo.stemLength[size],
		geo.stemSpacers[size] + geo.upperHeadset[size] + geo.stemStack[size], geo.barHeight[size],
		geo.sta_eff[size], geo.nsh[size]);
	Vue.set(bike['geometry']['seatedFit'], size, seatedFit)

	//Calculate Front Center
	const fc = geoForkRun(geo.forkLength[size] + geo.lowerHeadset[size] + geo.htl[size],
		geo.offset[size], geo.hta[size]) + geo.reach[size];

	const trail = geoTrail(bike.drivetrainInfo.tireDiameter_front, geo.offset[size], geo.hta[size]);
	Vue.set(bike['geometry']['trail'], size, trail)
	Vue.set(bike['geometry']['fc'], size, fc);
	Vue.set(bike['points'], 19, [fc + geo.bbOffset[size], (bike.drivetrainInfo.tireDiameter_front - bike.drivetrainInfo.tireDiameter) / 2 + geo.bbDrop[size]]);
	Vue.set(bike['geometry']['wheelbase'], size, geo.fc[size] + geo.rc[size]);
	Vue.set(bike['geometry']['htt'], size, geoHTT(geo.reach[size], geo.stack[size], geo.sta_eff[size]));
	//Set COG
	Vue.set(bike['geometry']['cogX'], size, bike.points[18][0]);
	Vue.set(bike['geometry']['cogY'], size, bike.points[18][1]);
}

function computeActiveFlipChip(bikeIndex) {
	const bike = state.bikeDataArray[bikeIndex];
	const chipResults = computeModLinkage(bike);
	if (chipResults.error) {
		console.log('Invalid Chip Config')
		chipResults.points = bike.points;
		chipResults.solvePoints = bike.points;
		chipResults.geometry = bike.geometry;
		chipResults.shockStroke = bike.solverInfo.shockStroke;
		chipResults.drivetrainInfo = bike.solverInfo.drivetrainInfo
	}
	Vue.set(bike['solverInfo'], 'chipSolution', chipResults);
}

function applySpringForce(index) {
	const springData = state.bikeViewArray[index].springData;
	const simpleCoil = state.bikeViewArray[index].simpleCoil;

	const springSettings = state.bikeViewArray[index].springSettings;
	const springType = springSettings.springType;
	const rateType = springSettings.rateType;

	const kinData = state.bikeViewArray[index].kinematicData;

	//Get base spring curve
	let spring = springType === 0 ? springData : simpleCoil;

	//Do nothing for empty spring or kinematic data object
	if (!('d_shock' in kinData) || !('data' in spring)) {
		//Set default display values for when spring or kinData is invalid
		springSettings.displaySag = '-';
		springSettings.airRate = '-';
		springSettings.coilRate = '-';
		springSettings.wheelTravel = 'd_shock' in kinData ? kinData.d_wheel_vert[kinData.d_shock.length - 1] : '-';
		springSettings.shockTravel = 'd_shock' in kinData ? kinData.d_shock[kinData.d_shock.length - 1] : '-';
		return;
	}

	let isCoil = false;
	if ('basePressure' in springData && springData.basePressure === 0 ||
		springType === 1) { isCoil = true; }

	//Calculate required rear support for rider weight------------------------
	//	Based on wheelbase length and COG position----------------------------
	const bike = state.bikeDataArray[index];
	const geo = bike.geometry;
	const size = bike.selectedSize;
	const wheelbase = geo.fc[size] + geo.rc[size];

	const rearWeight = (1 - (geo.cogX[size] + geo.rc[size]) / wheelbase) * geo.riderWeight[size];

	//User the shortest value of springTravel or Kinemtatic travel
	let shockTravel = kinData.d_shock[kinData.d_shock.length - 1];
	if (shockTravel > spring.maxTravel) { shockTravel = spring.maxTravel; }

	//Only get shock and wheel travel for the reduced shockTravel amount
	const d_shock = kinData.d_shock.filter(val => val <= shockTravel);
	const d_wheel = kinData.d_wheel_vert.slice(0, d_shock.length);

	springSettings.wheelTravel = d_wheel[d_shock.length - 1];
	springSettings.shockTravel = shockTravel;

	let sagIdx = 0; //Initialize

	//Compute spring rates----------------------------------------------------
	// Sag based calculation--------------------------------------------------
	if (rateType <= 1) {
		// Can set shock sag directly using even increment spacing
		sagIdx = Math.round(springSettings.shockSag * (d_shock.length - 1));

		//Get shock sag index for a given wheel sag percent
		if (rateType === 1) {
			//Get percentage of the max wheel travel for the found stroke length
			const sagTarget = d_wheel[d_wheel.length - 1] * springSettings.wheelSag;

			d_wheel.reduce((prev, curr, index) => {
				if (Math.abs(curr - sagTarget) < Math.abs(prev - sagTarget)) {
					sagIdx = index;
					return curr;
				} else { return prev; }
			});
		}

		const d_shock_sag = d_shock[sagIdx]; //shock displacement at sag
		const leverageSag = kinData.leverage[sagIdx]; //leverage at sag
		const reqForce = rearWeight * leverageSag; //require spring force at sag
		const baseForce = spring.data[String(d_shock_sag)][spring.spacerIndex]; //measure spring force at sag

		//Get rates for the given sag. 
		//	For coil the measured rate is without any spring so we add a rate in N/mm
		// 	For air we assume the rate is linearly proportional to the pressure
		if (isCoil) {
			const reqSpringForce = reqForce - baseForce;
			const rate = Math.round(100 * reqSpringForce / d_shock_sag) / 100;
			Vue.set(springSettings, 'coilRate', rate);
		} else {
			const forceMultiplier = reqForce / baseForce;
			const rate = Math.round(1000 * forceMultiplier * spring.basePressure) / 1000;
			Vue.set(springSettings, 'airRate', rate);
		}
	}

	//CRUNCH SOME NUMBERS FOR SCIENCE---------------------------------------------
	kinData.spring_force = [];
	kinData.energy = [];
	kinData.energy[0] = 0;
	kinData.wheel_force = [];
	kinData.wheel_rate = [];

	d_shock.forEach((xIdx, index) => {
		//Calculate spring force
		if (isCoil) {
			kinData.spring_force[index] = kinData.d_shockRaw[index] * springSettings.coilRate +
				spring.data[String(xIdx)][spring.spacerIndex];
		} else {
			const multiplier = springSettings.airRate / spring.basePressure;
			kinData.spring_force[index] = multiplier * spring.data[String(xIdx)][spring.spacerIndex];
		}

		if (index !== 0) {
			let dx = kinData.d_shockRaw[index] - kinData.d_shockRaw[index - 1];

			//Energy => integral of spring force. Displacement converted from mm to meters 
			kinData.energy[index] = kinData.spring_force[index] * dx / 1000 + kinData.energy[index - 1];

			//Correction factor for spring rounding. 
			if (!isCoil) {
				let slope = (kinData.spring_force[index] - kinData.spring_force[index - 1]) / dx;
				kinData.spring_force[index] += slope * (kinData.d_shockRaw[index] - xIdx);
			}
		}

		//Calculate wheel force
		kinData.wheel_force[index] = kinData.spring_force[index] / kinData.leverage[index];
	});

	// Wheel rate => first derivative of wheel force
	kinData.wheel_rate = simpleDeriv(d_wheel, kinData.wheel_force)

	// -------------------------------------------------------------------------------


	//Get the sag and travel info for display---------------------------------
	//	Sag will be where the wheel force is closest to the rear weight-------
	kinData.wheel_force.reduce((prev, curr, index) => {
		if (Math.abs(curr - rearWeight) < Math.abs(prev - rearWeight)) {
			sagIdx = index;
			return curr;
		} else { return prev; }
	});

	//For set wheel sag or set rate display shock sag
	if (rateType !== 0) {
		springSettings.displaySag = Math.round(100 * sagIdx / kinData.wheel_force.length) / 100;
	} else {
		springSettings.displaySag = Math.round(100 * d_wheel[sagIdx] / d_wheel[kinData.wheel_force.length - 1]) / 100;
	}
}

function createRecord(payload, previousValue, mutation) {
	//Allow overwriting of previous value
	if (payload.value === previousValue) { return; }
	if (debugUndoRedo) {
		console.log('creating record for ' + mutation)
		console.log(payload)
	}

	payload.previousValue = typeof payload.previousValue !== 'undefined' ? payload.previousValue : copy(previousValue);
	payload.currentValue = copy(payload.value);
	payload.noRecord = true; //Don't record undo mutations

	state.bikeViewArray[payload.index].changeRecord.length = state.bikeViewArray[payload.index].changeIndex + 1; //Trim all forward values
	state.bikeViewArray[payload.index].changeRecord.push({
		mutation: mutation,
		payload: payload,
	})

	if (state.bikeViewArray[payload.index].changeRecord.length > 5000) {
		state.bikeViewArray[payload.index].changeRecord.shift();
		state.bikeViewArray[payload.index].changeIndex -= 1;
	}
	state.bikeViewArray[payload.index].changeIndex += 1; //Increment change index
}


export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};

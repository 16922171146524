// function range(length) {
// 	return Array.from({ length: length }, (x, i) => i);
// }

function rangeInc(length) {
	return Array.from({ length: length + 1 }, (x, i) => i);
}

function dot(a, b) {
	if (a.length !== b.length) { return undefined; }
	let sum = 0;
	a.forEach((a_val, index) => {
		sum += a_val * b[index]
	});
	return sum;
}

function getWeights(point_of_fb_derive, point_range, fb_derive_order) {
	//Based on the approach found in:
	//
	// Generation of Finite Difference Formulas on Arbitrarily Spaced Grids
	// Bengt Fornberg
	// Mathematics of Computation
	// Vol. 51, No. 184 (Oct., 1988), pp. 699-706
	// DOI: 10.2307/2008770
	// https://www.jstor.org/stable/2008770

	const x0 = point_of_fb_derive;
	const a = point_range;
	const M = fb_derive_order;
	const N = a.length - 1;

	//Coefficient matrix. Will have 2D layer for each fb_derive
	let l = rangeInc(M);

	//2D layer for coefficient matrix. Will be N rows filled by regression
	//	Valid rows are n = m,m +1, . .., N. 
	let l_layer = rangeInc(N);
	l = l.map(() => l_layer.map(() => []));

	let c3 = 0;
	let c2 = 0;
	let min_range = 0;

	l[0][0][0] = 1;
	let c1 = 1;
	rangeInc(N).forEach(n => {
		if (n === 0) { return; }
		min_range = Math.min(n, M);
		c2 = 1;

		//For v
		rangeInc(n - 1).forEach(v => {
			c3 = a[n] - a[v];
			c2 = c2 * c3;
			if (n <= M) { l[n][n - 1][v] = 0; }

			l[0][n][v] = (a[n] - x0) * l[0][n - 1][v] / c3;
			rangeInc(min_range).forEach(m => {
				if (m === 0) { return; }
				l[m][n][v] = ((a[n] - x0) * l[m][n - 1][v] - m * l[m - 1][n - 1][v]) / c3;
			});
		});

		//For m
		l[0][n][n] = -c1 * (a[n - 1] - x0) * l[0][n - 1][n - 1] / c2;
		rangeInc(min_range).forEach(m => {
			if (m === 0) { return; }
			l[m][n][n] = c1 / c2 * (m * l[m - 1][n - 1][n - 1] - (a[n - 1] - x0) * l[m][n - 1][n - 1]);
		});

		c1 = c2;
	});
	return (l[M][N]);
}

export function simpleDeriv(x, y) {
	//Use 5 point fornberg weights for endpoints 
	//Use 3 point evenly spaced weights for main code body
	//	This drastically improves performance over calculating
	//	fornberg weights for each point
	const deriv = [];
	const end = x.length - 1;

	//Initial Value
	let x_set = [x[0], x[1], x[2], x[3], x[4]];
	let y_set = [y[0], y[1], y[2], y[3], y[4]];
	let weights = getWeights(x[0], x_set, 1);
	deriv[0] = dot(y_set, weights);

	for (let i = 1; i < end - 1; i++) {
		deriv[i] = (y[i + 1] / 2 - y[i - 1] / 2) / ((x[i + 1] - x[i - 1]) / 2)
	}

	//End Value
	x_set = [x[end - 4], x[end - 3], x[end - 2], x[end - 1], x[end]];
	y_set = [y[end - 4], y[end - 3], y[end - 2], y[end - 1], y[end]];
	weights = getWeights(x[end], x_set, 1);
	deriv[end] = dot(y_set, weights);

	return deriv;
}


export function fornberg_5(x, y, d_order) {
	const deriv = [];
	let end = x.length - 1;
	let x_set = [];
	let y_set = [];
	let x0, weights;


	for (let i = 0; i < 2; i++) {
		x_set = [x[0], x[1], x[2], x[3], x[4]];
		y_set = [y[0], y[1], y[2], y[3], y[4]];
		x0 = x[i];

		weights = getWeights(x0, x_set, d_order);
		deriv.push(dot(y_set, weights));
	}

	for (let i = 2; i < end - 2; i++) {
		x_set = [x[i - 2], x[i - 1], x[i], x[i + 1], x[i + 2]];
		y_set = [y[i - 2], y[i - 1], y[i], y[i + 1], y[i + 2]];
		x0 = x[i];

		weights = getWeights(x0, x_set, d_order);
		deriv.push(dot(y_set, weights));
	}


	for (let i = end - 2; i <= end; i++) {
		x_set = [x[end - 4], x[end - 3], x[end - 2], x[end - 1], x[end]];
		y_set = [y[end - 4], y[end - 3], y[end - 2], y[end - 1], y[end]];
		x0 = x[i];

		weights = getWeights(x0, x_set, d_order);
		deriv.push(dot(y_set, weights));
	}
	// return deriv.map(e => Math.round(1000 * e) / 1000)
	return deriv;
}


export function fornberg_9(x, y, d_order) {
	const deriv = [];
	let end = x.length - 1;
	let x_set = [];
	let y_set = [];
	let x0, weights;


	for (let i = 0; i < 4; i++) {
		x_set = [x[0], x[1], x[2], x[3], x[4], x[5], x[6], x[7], x[8]];
		y_set = [y[0], y[1], y[2], y[3], y[4], y[5], y[6], y[7], y[8]];
		x0 = x[i];

		weights = getWeights(x0, x_set, d_order);
		deriv.push(dot(y_set, weights));
	}

	for (let i = 4; i < end - 4; i++) {
		x_set = [x[i - 4], x[i - 3], x[i - 2], x[i - 1], x[i], x[i + 1], x[i + 2], x[i + 3], x[i + 4]];
		y_set = [y[i - 4], y[i - 3], y[i - 2], y[i - 1], y[i], y[i + 1], y[i + 2], y[i + 3], y[i + 4]];
		x0 = x[i];

		weights = getWeights(x0, x_set, d_order);
		deriv.push(dot(y_set, weights));
	}


	for (let i = end - 4; i <= end; i++) {
		x_set = [
			x[end - 8], x[end - 7], x[end - 6], x[end - 5], x[end - 4],
			x[end - 3], x[end - 2], x[end - 1], x[end]
		];
		y_set = [
			y[end - 8], y[end - 7], y[end - 6], y[end - 5], y[end - 4],
			y[end - 3], y[end - 2], y[end - 1], y[end]
		];
		x0 = x[i];

		weights = getWeights(x0, x_set, d_order);
		deriv.push(dot(y_set, weights));
	}
	// return deriv.map(e => Math.round(1000 * e) / 1000)
	return deriv;
}
<template>
	<div class="firebase-auth-login">
		<div class="firebase-login-message">{{errorMessage}}</div>
		<div v-if="emailState===0" class="multi-button-vert-cont">
			<ButtonStandard name="Login with Google" @click="googleLogin" :flex="true" :outline="true">
				<template v-slot>
					<img src="google.svg" alt="Google Logo" class="auth-login-button-icon">
					<span style="margin: auto">Login with Google</span>
				</template>
			</ButtonStandard>
			<ButtonStandard name="Login with Microsoft" @click="msLogin" :flex="true" :outline="true">
				<template v-slot>
					<img src="microsoft.svg" alt="Microsoft Logo" class="auth-login-button-icon">
					<span style="margin: auto">Login with Microsoft</span>
				</template>
			</ButtonStandard>
			<ButtonStandard name="Email" test="email-login" @click="startEmail" :flex="true">
				<template v-slot>
					<img src="mail.svg" alt="Email Icon" class="auth-login-button-icon">
					<span style="margin: auto">Login with Email</span>
				</template>
			</ButtonStandard>
			<ButtonStandard name="Sign Up" @click="emailState = 3" :flex="true" />
		</div>
		<div v-if="emailState===1">
			<TextInputMD name="Email" test="fb-email-input" autocomplete="on" v-model="email" :error="emailErr" />
			<TextInputMD name="Password" test="fb-password-input" :type="'password'" autocomplete="on" :error="passwordErr" v-model="password" @enter="loginEmail" />
			<transition name="slide-out">
				<a v-if="resetLink" class="reset-password-link" @click="resetPassword">Send password reset email?</a>
			</transition>
			<div class="multi-button-cont">
				<ButtonStandard name="Cancel" test="fb-cancel-login-state" :error="true" :clear="true" :flex="true" @click="resetAuth" />
				<ButtonStandard name="Submit" test="fb-submit-email-login" :flex="true" :spinner="isSubmitting" @click="loginEmail" />
			</div>
			<!--  -->
		</div>
		<div v-if="emailState===3">
			<TextInputMD name="Email" test="fb-email-input" autocomplete="on" v-model="email" :error="emailErr" />
			<TextInputMD name="Name" test="fb-user-name-input" autocomplete="on" v-model="name" :error="nameErr" />
			<TextInputMD name="Password" test="fb-password-input" :type="'password'" autocomplete="on" :error="passwordErr" v-model="password" />
			<TextInputMD name="Verify Password" test="fb-password-verify" :type="'password'" autocomplete="on" :error="passwordVerifyErr" v-model="passwordVerify" @enter="signupEmail" />
			<div class="multi-button-cont">
				<ButtonStandard name="Cancel" test="fb-cancel-signup-state" :flex="true" :error="true" :clear="true" @click="resetAuth" />
				<ButtonStandard name="Submit" test="fb-submit-sign-up" :flex="true" :spinner="isSubmitting" @click="signupEmail" />
			</div>
			<!--  -->
		</div>
	</div>
</template>
<script>
import { fs_google_login, fs_email_login, fs_email_signup, fs_ms_login, fs_reset_pass } from "@/firebaseConfig.js";
import { mapActions, mapGetters } from "vuex";
import ButtonStandard from "@/components/functional/ButtonStandard.vue";
import TextInputMD from "@/components/functional/TextInputMD.vue";

export default {
	name: 'FirebaseAuthLogin',
	components: {
		ButtonStandard,
		TextInputMD,
	},

	data() {
		return {
			email: '',
			password: '',
			passwordVerify: '',
			name: '',
			emailState: 0,
			emailErr: false,
			passwordErr: false,
			resetLink: false,
			passwordVerifyErr: false,
			nameErr: false,
			isSubmitting: false,

			errorMessage: '',
		};
	},

	computed: {
		...mapGetters('stateViewLev', ['getViewData']),
		user: function() { return this.getViewData('user'); }
	},

	methods: {
		...mapActions('stateViewLev', ['resetUser']),

		resetPassword: async function() {
			const res = await fs_reset_pass(this.email);
			if (res === 'auth/network-request-failed') {
				this.errorMessage = 'No network connection detected';
			} else {
				this.resetLink = false;
			}
		},

		googleLogin: async function() {
			if (process.env.IS_ELECTRON) {
				window.ipcRenderer.invoke('partner-login', 'google');
				return;
			}

			let res = await fs_google_login();
			if (res === 'auth/user-disabled') {
				this.errorMessage = 'User account is disabled. Please contact the administrator';
			}
		},

		msLogin: async function() {
			if (process.env.IS_ELECTRON) {
				window.ipcRenderer.invoke('partner-login', 'ms');
				return;
			}
			let res = await fs_ms_login();
			if (res.error) {
				if (res.code === 'auth/user-disabled') {
					this.errorMessage = 'User account is disabled. Please contact the administrator';
				} else if (res.code === 'auth/account-exists-with-different-credential') {
					this.errorMessage = 'An account already exists with the same email address. Please login using a provider associated with this email address.';
				} else {
					this.errorMessage = 'Issues validating with the Microsoft servers. Please try again or contact support. '
				}
			}
		},

		startEmail: function() {
			this.emailState = 1;
		},

		loginEmail: async function() {
			this.isSubmitting = true;
			const res = await fs_email_login(this.email, this.password);

			if (res === 'auth/invalid-email') {
				this.emailErr = true;
				this.passwordErr = true;
			}
			if (res === 'auth/wrong-password') {
				this.emailErr = false;
				this.passwordErr = true;
				this.resetLink = true;
			}
			if (res === 'auth/user-disabled') {
				this.errorMessage = 'User account is disabled. Please contact the administrator';
			}
			if (res === 'auth/too-many-requests') {
				this.errorMessage = 'Too many failed logins. Please reset your password';
				this.passwordErr = true;
				this.resetLink = true;
			}
			//If no user found direct to sign up state
			if (res === 'auth/user-not-found') {
				this.resetError();
				this.emailState = 3;
				this.password = '';
				this.errorMessage = 'No user with that email. Please sign up.';
			}

			this.isSubmitting = false;
		},

		signupEmail: async function() {
			this.resetError();
			if (this.name.length < 2) {
				this.nameErr = true;
				this.errorMessage = 'A display name must be entered'
				return;
			}

			if (this.name.length > 100) {
				this.nameErr = true;
				this.errorMessage = 'That is a very long name friend. Sorry but the display name must be less than 100 characters in length';
				return;
			}

			if (this.password !== this.passwordVerify) {
				this.passwordVerifyErr = true;
				this.errorMessage = 'The passwords do not match';
				this.passwordVerify = '';
				return;
			}

			this.isSubmitting = true;
			const res = await fs_email_signup(this.email, this.password, this.name);

			if (res === 'auth/weak-password') {
				this.passwordErr = true;
				this.errorMessage = 'Password must be longer than 6 characters';
				this.isSubmitting = false;
				return;
			}

			if (res === 'auth/email-already-in-use') {
				this.emailErr = true;
				this.errorMessage = 'An account with that email already exists. Please login using your email and password or another authentication provider';
				this.isSubmitting = false;
				return;
			}

			//Trigger reset on user change
			this.resetUser();

			this.isSubmitting = false;
		},

		resetAuth: function() {
			this.email = '';
			this.password = '';
			this.passwordVerify = '';
			this.emailState = 0;

			this.resetError();
		},

		resetError: function() {
			this.emailErr = false;
			this.passwordErr = false;
			this.passwordVerifyErr = false;
			this.nameErr = false;
			this.errorMessage = '';
			this.resetLink = false;
		}
	}
}
</script>
<style>
.firebase-auth-login {
	width: 100%;
	padding: 0rem 1rem 1rem 1rem;
}

.firebase-login-message {
	margin: .5rem;
	text-align: left;
}

.auth-login-button-icon {
	height: 2rem;
	width: 2rem;
	position: absolute;
	left: .5rem;
	bottom: .5rem;
}

.reset-password-link {
	display: block;
	text-align: left;
	text-decoration: underline;
	margin-bottom: .25rem;
	color: var(--color-error);
	cursor: pointer;
}
</style>

import Home from "@/views/Home.target.vue";
import LevDB from "@/views/LevDB.vue";
import AccountSettings from "@/views/AccountSettings.target.vue";
import TeamSettings from "@/views/TeamSettings.vue";

import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'home',
		component: Home
	},
	{
		path: '/lev',
		name: 'lev',
		meta: {
			requireAuth: true,
			requireSub: true,
		},

		// route level code-splitting
		component: function() {
			return import( /* webpackChunkName: "lev" */ '../views/Lev.vue')
		}
	},
	{
		path: '/account',
		name: 'account',
		component: AccountSettings,
		meta: { requireAuth: true, },
	},
	{
		path: '/db',
		name: 'db',
		component: LevDB,
		meta: { requireAuth: true, },
	},
	{
		path: '/team',
		name: 'team',
		component: TeamSettings,
		meta: { requireAuth: true, },
	}
]

const router = new VueRouter({
	mode: process.env.IS_ELECTRON ? 'hash' : 'history',
	routes
})

router.beforeEach((to, from, next) => {
	const user = store.state.stateViewLev.user;
	const claims = store.state.stateViewLev.claims;
	const requireAuth = to.matched.some(record => record.meta.requireAuth);
	const requireSub = to.matched.some(record => record.meta.requireSub);

	let preventRoute = false;

	//Check for logged in user and save route if not logged in
	if (!user && requireAuth) {
		const loginpath = window.location.pathname;
		next({ path: '/', query: { from: loginpath } });
		return;
	}
	//Check for paying sub
	if (user && !claims.auth_perm && requireSub) { preventRoute = true; }
	//Prevent dev site access
	if (user && requireAuth && (process.env.VUE_APP_IS_DEV === 'true') && !claims.app_admin) {
		preventRoute = true;
	}

	if (preventRoute) { next(from.path); } else { next(); }

	//Restrict team access
	if (to.name === "team") {
		const userInfo = store.state.stateViewLev.userInfo;
		const user = store.state.stateViewLev.user;

		const isTeamPanel = function() {
			if (!userInfo) { return false; }
			if (!userInfo.planQty || userInfo.planQty <= 1) { return false; }
			if (claims.team_id && claims.team_id !== user.uid) { return false; }
			return true;
		}

		if (!isTeamPanel()) { next(from.path); } else { next(); }
	}
})

export default router

<template>
	<div class="card-shadow as-panel">
		<h2>Account</h2>
    <p>
      Please make any account changes using the latest version of the app
      available <a href="https://app.syn.bike/account">here</a>. Any changes
      will applied to both app versions.
    </p>
	</div>
</template>
<script>
import { mapState } from "vuex";

export default {
	name: "AccountSettings",
	components: {},

	computed: {
		...mapState('stateViewLev', ['claims', 'isMobile']),
	}
};
</script>
<style>
.as-panel {
	display: flex;
	flex-direction: column;
	min-width: 50rem;
	width: 50rem;
	max-width: 60rem;
	height: 40rem;
	overflow: hidden;
	margin: 2rem auto;
	padding: 3rem;
}

.as-panel a,
.as-panel p {
  line-height: 1.3;
  font-size: 1.6rem;
  /* text-align: center; */
}

.as-panel a {
  display: inline;
  text-decoration: underline;
}

.as-user__notifications {
	text-align: left;
	margin: 1rem;
}

.as-user__line-item {
	padding: 1rem 0;
	padding-top: 0;
	margin-bottom: 1rem;
	/*min-height: 5rem;*/
	align-items: center;
	border-bottom: .1rem solid var(--color-bg-secondary-light);
}

.as-user__line-item--text {
	line-height: 3rem;
	text-align: left;
	font-weight: 700;
}


@media(max-width: 500px) {
	.as-panel {
		width: 100%;
		max-width: 100%;
		min-width: 100%;
		height: auto;
		margin: 0;
		min-height: 30rem;
		border-radius: 0 !important;
		border-bottom: .1rem solid var(--color-bg-secondary-light);
		box-shadow: none !important;
		padding: 1rem;
	}
}
</style>

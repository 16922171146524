<template>
	<div class="flex-1 flex-column">
		<transition name="fade" mode="out-in">
			<!-- FOR NO USER OBJECT SHOW LOGIN -->
			<div v-if="viewState === 0" class="landing--auth" key="login">
				<div class="landing__card--auth card-shadow">
					<div class="landing__login-image">
						<div class="landing__login-icon card-shadow">
							<AppIcon type="login" />
						</div>
					</div>
					<FirebaseAuthLogin />
					<div class="landing--links">
						By signing in you agree to our <a :href="siteURL + '/privacy'" target="_blank">Privacy Policy</a> and <a :href="siteURL + '/terms'" target="_blank">Terms and Conditions</a>.
					</div>
				</div>
			</div>
			<!--  -->
			<!--  -->
			<div v-else-if="viewState === 1" class="flex-column flex-center flex-1">
				<div class="landing__card--auth card-shadow">
					<div class="landing__login-image">
						<div class="landing__login-icon card-shadow">
							<AppIcon type="launch" />
						</div>
					</div>
					<h3>Development</h3>
					<span>Welcome to the future. This is where all the fun new stuff coming down the pipeline is tested, broken, and fixed!</span>
				</div>
			</div>
			<!--  -->
			<!--  -->
			<LandingEmailVerify v-else-if="viewState === 2" />
			<!--  -->
			<!--  -->
			<LandingExpired v-else-if="viewState === 3" />
			<!--  -->
			<!--  -->
			<div v-else-if="viewState === 4" class="flex-column flex-center flex-1" key="dashboard">
				<router-link data-test="landing-enter-app" tag="div" to="/lev" class="landing__card card-shadow card-shadow--hover">
					<div class="landing__login-image">
						<div class="landing__login-icon card-shadow">
							<AppIcon type="link" />
						</div>
					</div>
					<div class="flex-row flex-center landing__lev-title">
						<h3 style="padding: 0; margin-right: .7rem">syn Link</h3>
						<IconArrow size="30px" />
					</div>
				</router-link>
				<div v-if="subStatus" class="landing__sub-warning">
					<div class="landing__sub-warning__chip warning">
						{{subStatus}}
					</div>
					<ButtonStandard name="Upgrade Plan" :outline="true" @click="openStripeModal" :btnstyle="subButton" />
				</div>
				<div v-if="browserWarn" class="landing__browser-warning warning">
					<B>Browser Warning: </b>syn works best with recent versions of Chrome, Edge, and Safari.
					<br>Non webkit browsers such as Firefox can have performance issues.
				</div>
				<div class="landing__alt-links flex-row flex-wrap flex-center">
					<div>
						<router-link to="/db" class="landing__circle-link">
							<AppIcon type="file" />
						</router-link>
						<h5>Files</h5>
					</div>
					<div>
						<router-link to="/account" class="landing__circle-link">
							<AppIcon type="account" />
						</router-link>
						<h5>Account</h5>
					</div>
					<div v-if="isTeamPanel">
						<router-link to="/team" class="landing__circle-link">
							<AppIcon type="team" />
						</router-link>
						<h5>Team</h5>
					</div>
					<div>
						<a :href="docsURL" target="_blank" class="landing__circle-link">
							<AppIcon type="help" />
						</a>
						<h5>Help</h5>
					</div>
				</div>
			</div>
			<!--  -->
			<!--  -->
			<div v-else class="vld-parent flex-1">
				<loading :active="true" :is-full-page="false" :background-color="$colorBackground" :color="$colorPrimary"></loading>
			</div>
		</transition>
		<ModalWindow v-if="subStatus" :show="stripeModal" @close="closeStripeModal" maxWidth="50rem" minWidth="50rem" :noClose="true" :hideContent="hideStripeContent" :padding="'1rem 2rem 2rem 2rem'">
			<template v-slot:body>
				<StripeForm :startingState="0" @cancel="hideStripeContent = true" :newPlan="true" />
			</template>
		</ModalWindow>
	</div>
</template>
<script>
import FirebaseAuthLogin from "@/components/landing/FirebaseAuthLogin.vue";
import LandingExpired from "@/components/landing/LandingExpired.vue";
import LandingEmailVerify from "@/components/landing/LandingEmailVerify.vue";
import AppIcon from "@/components/icons/AppIcon.vue";
import IconArrow from "@/components/icons/IconArrow.vue";

import ButtonStandard from "@/components/functional/ButtonStandard.vue";
import ModalWindow from "@/components/ModalWindow.vue";
import StripeForm from "@/components/account/StripeForm.vue";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import { mapState } from "vuex";

export default {
	name: "Home",

	components: {
		FirebaseAuthLogin,
		LandingExpired,
		LandingEmailVerify,
		AppIcon,
		IconArrow,
		Loading,

		ButtonStandard,
		ModalWindow,
		StripeForm,
	},

	data() {
		return {
			isDev: (process.env.VUE_APP_IS_DEV === 'true'),

			extendTime: false, //Overrides expiry date for leeway period
			docsURL: process.env.VUE_APP_DOC_PAGE,
			siteURL: process.env.VUE_APP_MAIN_SITE,

			dismissBrowserWarn: false,

			auth_perm: '',

			stripeModal: false,
			hideStripeContent: false,

			subButton: {
				padding: '0 2rem',
				width: '100%',
				marginTop: '1rem',
				borderRadius: '1.5rem',
			},
		}
	},

	computed: {
		...mapState('stateViewLev', ['user', 'claims', 'userInfo', 'isMobile']),

		viewState: function() {
			//Login page
			if (!this.user) { return 0; }

			//Development block
			if (this.isDev && !this.claims.app_admin) { return 1; }

			//Email Verification
			// if (this.user && !this.user.emailVerified) { return 2; }

			//Expired
			if (this.subExp && this.userInfo) { return 3; }

			//Standard
			if (this.userInfo && Object.keys(this.userInfo).length > 0) { return 4; }

			return 'loading'
		},

		isTeamPanel: function() {
			if (!this.userInfo) { return false; }
			if (!this.userInfo.planQty ||
				this.userInfo.planQty <= 1) { return false; }
			if (this.claims.team_id &&
				this.claims.team_id !== this.user.uid) { return false; }
			return true;
		},

		subStatus: function() {
			if (!this.userInfo) { return false; }

			if (this.userInfo.sub_exp) {
				const subExp = parseInt(this.userInfo.sub_exp);
				const currentTime = Math.floor(Date.now() / 1000);
				const warningWindow = parseInt(process.env.VUE_APP_EXPIRY_WARN) * 24 * 60 * 60 + 1; //Must offset by 1 second
				if (subExp && subExp - currentTime < warningWindow) {
					const statusText = this.userInfo.plan ? '' : 'Trial ';
					const expDate = new Date(subExp * 1000);
					let displayDate = new Intl.DateTimeFormat('en-CA').format(expDate);
					return statusText + 'Expires: ' + displayDate;
				}
			}
			return false;
		},

		subExp: function() {
			if (!this.userInfo) { return false; }
			if (this.userInfo.sub_exp) {
				const subExp = parseInt(this.userInfo.sub_exp);
				const currentTime = Math.floor(Date.now() / 1000);
				if (subExp && subExp - currentTime < 1) {
					return true;
				}
			}
			return false;
		},

		browserWarn: function() {
			return (this.$browserDetect.isOpera || this.$browserDetect.isFirefox) && !this.dismissBrowserWarn ? true : false;
		}
	},

	methods: {
		closeStripeModal: function() {
			this.stripeModal = false;
		},

		openStripeModal: function() {
			this.hideStripeContent = false;
			this.stripeModal = true;
		}
	}
};
</script>
<style>
.landing__alt-links {
	padding: 1rem;
	background-color: transparent;
}

.landing__alt-links>div {
	margin: 1rem 2rem;
}

.landing__circle-link {
	display: block;
	min-width: 100px;
	width: 100px;
	min-height: 100px;
	height: 100px;
	padding: 10px;
	margin: 5px;
	margin-bottom: .75rem;

	background-color: var(--color-bg);
	border: .3rem solid var(--color-font-bg);
	border-radius: 50% !important;

	stroke: var(--color-font-bg);
	fill: var(--color-font-bg);

	transition: all 500ms cubic-bezier(0.505, 0.325, 0.360, 0.760);
}

.landing__circle-link:hover {
	box-shadow: 0 0 0 5px var(--color-primary-light);
}

.landing__circle-link:active {
	box-shadow: none;
}

.landing__card:active {
	box-shadow: none !important;
}

.landing--auth {
	flex: 1;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 4rem 0;

}

.landing__card {
	display: flex;
	flex-direction: column;
	align-items: center;
	min-width: 300px;
	width: 25rem;
	margin-bottom: 2rem;

	padding: 1.5rem;
	padding-bottom: 2.5rem;
	border-radius: 5rem !important;
	cursor: pointer;

	transition: .3s;
}

.landing__card:hover .landing__login-image {
	box-shadow: 0 0 0 10px var(--color-primary-light)
}

.landing__card--auth {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 30rem;
	padding: 1.5rem;
	padding-bottom: 2.5rem;
	border-radius: 5rem !important;

	transition: .3s;
}

.landing__login-image {
	position: relative;
	width: 200px;
	height: 200px;
	margin: 2rem auto;

	border-radius: 50%;
	background-image: linear-gradient(45deg, #720793 0%, #47095B 90%);
	opacity: .95;

	transition: .4s;
}

.landing__login-icon {
	position: absolute;
	top: 40px;
	left: 40px;
	width: 120px;
	height: 120px;
	padding: 10px;

	border-radius: 50% !important;
	border: .3rem solid var(--color-font-bg);
	stroke: var(--color-font-bg);
	fill: var(--color-primary);
}

.landing__lev-title {
	stroke: var(--color-font-bg);
	padding: 0 2rem;
}

.landing__sub-warning {
	background-color: transparent;
}

.landing__browser-warning {
	margin-top: 2rem;
	background-color: transparent;
	text-align: center;
	line-height: 2rem;

	max-width: 600px;
}

.landing__sub-warning__chip {
	font-weight: 500;
	height: 3rem;
	padding: 0 1rem;
	line-height: 2.8rem;

	border-radius: 1.5rem;
	border: .1rem solid;
	background-color: transparent;
}

.landing--links {
	font-size: 1rem;
	margin: 1rem;
}

.landing--links>a {
	font-size: 1rem;
	text-decoration: underline;
}

@media(max-width: 900px) {}

@media(max-width: 500px) {
	.landing__card--auth {
		width: 100%;
		box-shadow: none !important;
		padding: 1rem 0;
	}

	.landing__card {
		margin-top: 3rem;
	}
}
</style>
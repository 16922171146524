<template>
	<div v-if="isTeamPanel" class="card-shadow ts__panel vld-parent">
		<loading :active="isCreatingOrDeleting" :is-full-page="false" :background-color="$colorBackground" :color="$colorPrimary"></loading>
		<!------------------------------------------->
		<!-- CREATE TEAM IF NONE EXISTS -->
		<div v-if="!claims.team_id" class="flex-column" style="height: 10rem">
			<ButtonStandard :flex="true" :clear="true" name="Create Team" :btnstyle="{margin: '1rem 0'}" @click="createTeam">
			</ButtonStandard>
		</div>
		<!------------------------------------------->
		<!-- Team Controls -->
		<div v-if="claims.team_id" class="flex-column flex-1 no-overflow">
			<!-- User Input Row -->
			<h6 style="text-align: left" class="border--bottom">Add Members:</h6>
			<div class="ts__inputs">
				<!-- Add user Controls -->
				<div class="flex-column flex-1">
					<div class="ts__panel__message" :class="{'error' : messageErr}">
						<transition name="slide">
							<span v-if="message" class="ts__panel__message">{{message}}</span>
						</transition>
					</div>
					<div class="flex-mobile">
						<TextInputST name="User Email" v-model="inputEmail" :error="emailErr" type="none" extmargin="true" @enter="addUpdateUser" />
						<div ref="emailinput" class="flex-row" :class="{'margin--top' : isMobile}">
							<ListSelector :itemList="userPermissions" v-model="inputPermissions" width="8rem" margin=" 0 .5rem" />
							<ButtonStandard :name="isExistingMember ? 'Update User' : 'Invite User'" @click="addUpdateUser" :btnstyle="{width: '15rem'}">
								<template v-slot:icon-slot>
									<IconValidate :valid="updateStatus === 1" :invalid="updateStatus === 2" :loading="isUpdating" />
								</template>
							</ButtonStandard>
						</div>
					</div>
				</div>
				<!-- Team image and license counter -->
				<LicenseCounter v-if="userInfo && userInfo.planQty" :totalQty="userInfo.planQty" :memberQty="numLicensedMembers" />
			</div>
			<!--  -->
			<!--  -->
			<!-- Member List Header -->
			<div class="ts__member__header">
				<h6 style="text-align: left">Members:</h6>
				<div class="flex-row">
					<div class="ts__member__header-label">License</div>
					<div class="ts__member__header-label">Permissions</div>
					<div class="ts__member__header-label"></div>
				</div>
			</div>
			<!-- Member List -->
			<div class="flex-column flex-1 scroll-y margin--bottom">
				<div class="ts__member__cont" v-if="memberList.length > 0">
					<div v-for="(member, index) in memberList" :key="'team-member-' + index" class="ts__member">
						<div v-if="!isMobile" class="ts__member__image">
							<img v-if="member.picture" :src="member.picture" alt="User Thumbnail" class="image-center">
						</div>
						<div v-if="!isMobile" class="ts__member__text flex-2"> {{member.display_name}} </div>
						<div class="ts__member__text flex-3"> {{member.email}} </div>
						<ButtonStandard name="Remove" :clear="true" :btnstyle="tableBtn" @click="changeUserAuth(index, member.is_auth)">
							<div class="ts__license-icon">
								<IconValidate :valid="member.is_auth && loadingAuthIndex !== index" :invalid="!member.is_auth && loadingAuthIndex !== index" :loading="isChangingAuth && changingAuthIndex === index" />
							</div>
						</ButtonStandard>
						<!--  -->
						<ButtonStandard :name="userPermissions[member.permissions]" :clear="true" :btnstyle="{width: '7rem', margin: '.5rem'}" @click="changePerm(member.email, member.permissions)" />
						<!-- Remove Team Member -->
						<transition name="fade" mode="out-in">
							<ButtonStandard v-if="index > 0 && index !== removeMemberIndex" name="Remove" :error="true" :outline="true" :btnstyle="tableBtn" @click="deleteUser(member.id, index)" />
							<div v-if="removeMemberIndex === index || index === 0" class="ts__member__loading-icon" :style="tableBtn">
								<IconValidate v-if="index !== 0" :loading="removeMemberIndex === index && isRemovingMember" :valid="removeMemberStatus === 1" :invalid="removeMemberStatus === 2" />
							</div>
						</transition>
					</div>
				</div>
			</div>
			<!--  -->
			<div class="ts__dz" :class="{'ts__dz--clicked' : hideDZ}">
				<span class="ts__dz__header" @click="hideDZ = !hideDZ">Danger Zone</span>
				<transition name="fade" mode="out-in">
					<ButtonStandard v-if="!confirmDelete" :name="isMobile ? 'Delete Team' : 'Remove all members and delete team'" :flex="true" :error="true" @click="initTeamDelete" />
					<ButtonStandard v-else name="Confirm Team Delete" :flex="true" :outline="true" :error="true" @click="deleteTeam" />
				</transition>
			</div>
		</div>
	</div>
</template>
<script>
import ButtonStandard from "@/components/functional/ButtonStandard.vue";
import TextInputST from "@/components/functional/TextInputST.vue";
import ListSelector from "@/components/functional/ListSelector.vue";
import IconValidate from "@/components/icons/IconValidate.vue";
import LicenseCounter from "@/components/team/LicenseCounter.vue";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import { axiosIPC } from "@/modules/sendRequest.js";
import { mapState, mapActions } from "vuex";
import { auth } from "@/firebaseConfig.js";

export default {
	name: "TeamSettings",
	components: {
		ButtonStandard,
		TextInputST,
		ListSelector,
		IconValidate,
		Loading,
		LicenseCounter
	},

	data() {
		return {
			inputEmail: '',
			emailErr: false,

			inputPermissions: 0,
			userPermissions: ['Viewer', 'Editor', 'Admin'],

			isRemovingMember: false,
			removeMemberIndex: -1,
			removeMemberStatus: 0,

			isUpdating: false,
			updateStatus: 0,

			isChangingAuth: false,
			changingAuthIndex: -1,
			loadingAuthIndex: -1,

			isCreatingOrDeleting: false,

			message: '',
			messageTimer: '',
			messageErr: false,

			team_photo: '',

			tableBtn: {
				margin: '.5rem',
				width: '7.5rem',
			},

			hideDZ: true,
			confirmDelete: false,
		};
	},

	computed: {
		...mapState('stateViewLev', ['isMobile', 'user', 'claims', 'userInfo', 'teamMemberList']),

		memberList: function() {
			const owner = this.teamMemberList.find((item) => item.id === this.user.uid);
			const list = this.teamMemberList.filter((item) => item.id !== this.user.uid);
			if (owner) { list.unshift(owner); }
			return list;
		},

		numLicensedMembers: function() {
			return this.memberList.reduce((total, member) => {
				return member.is_auth ? total + 1 : total;
			}, 0)
		},

		memberEmailList: function() {
			return this.memberList.map(member => member.email);
		},

		isExistingMember: function() {
			const status = this.memberList.find((member) => {
				return member.email === this.inputEmail && !member.pending
			});
			return status ? true : false;
		},

		loadingBarStyle: function() {
			return {
				strokeDashoffset: 100 - this.numLicensedMembers / this.userInfo.planQty * 100,
			}
		},

		//Clear window if team auth is removed
		isTeamPanel: function() {
			if (!this.userInfo) { return false; }
			if (!this.userInfo.planQty ||
				this.userInfo.planQty <= 1) { return false; }
			if (this.claims.team_id &&
				this.claims.team_id !== this.user.uid) { return false; }
			return true;
		},
	},

	methods: {
		...mapActions('stateViewLev', ['setViewData', 'resetUser', 'syncUserData']),

		setMessage: function(msg, error = false) {
			clearTimeout(this.messageTimer);
			this.message = msg;
			this.messageErr = error;
			this.messageTimer = setTimeout(() => this.message = '', 7000);
		},

		createTeam: async function() {
			this.isCreatingOrDeleting = true;
			if (this.isUpdating) { return; }
			this.isUpdating = true;
			const data = {
				type: 'create_team',
				user: {}
			}
			const response = await this.sendReqest(data, 'create')
			if ('error' in response) {
				this.setMessage(response.error.message, true);
				this.updateStatus = 2;
				setTimeout(this.clearUpdateMember, 1500);
			} else {
				this.isUpdating = false;
				await this.resetUser('async');
				this.syncUserData();
			}

			this.hideDZ = true;
			setTimeout(() => { this.isCreatingOrDeleting = false; }, 700)
		},

		initTeamDelete: function() {
			this.confirmDelete = true;
			setTimeout(() => { this.confirmDelete = false; }, 3000);
		},

		deleteTeam: async function() {
			this.isCreatingOrDeleting = true;
			const data = {
				type: 'delete_team',
				user: {}
			}
			await this.sendReqest(data, 'delete')

			await this.resetUser('async');
			this.syncUserData();

			this.isCreatingOrDeleting = false;
		},

		addUpdateUser: async function() {
			if (this.isUpdating) { return; }

			let email = this.validateEmail();
			if (email === 'error') { return; }

			const type = this.isExistingMember ? 'update_member' : 'add_member'
			const data = {
				type: type,
				user: {
					email: email,
					permissions: this.inputPermissions,
				}
			}

			this.isUpdating = true;

			const res = await this.sendReqest(data, 'member-add');
			if ('error' in res) {
				this.setMessage(res.error.message, true);
				this.updateStatus = 2;
			} else {
				this.updateStatus = 1;
			}

			setTimeout(this.clearUpdateMember, 1500);
		},

		deleteUser: async function(remove_uid, index) {
			if (this.isRemovingMember) { return; } //Should not be needed as button disappears
			this.removeMemberIndex = index;
			this.isRemovingMember = true;
			const data = {
				type: 'delete_member',
				remove_uid: remove_uid,
			}

			const res = await this.sendReqest(data, 'member-delete');
			if ('error' in res) {
				this.removeMemberStatus = 2;
			} else {
				this.removeMemberStatus = 1;
			}

			setTimeout(this.clearRemoveMember, 1500);
		},

		changeUserAuth: async function(index, status) {
			if (this.isChangingAuth) { return; }
			const data = {
				type: 'auth_member',
				member_uid: this.memberList[index].id
			}
			let route = 'auth';

			if (status) {
				data.type = 'deauth_member';
				route = 'deauth';
			}

			this.isChangingAuth = true;
			this.changingAuthIndex = index;
			this.loadingAuthIndex = index;
			const res = await this.sendReqest(data, route);

			if ('error' in res) {
				this.setMessage(res.error.message, true);
			} else {
				this.setMessage(res.message);
			}

			this.loadingAuthIndex = -1;
			setTimeout(() => {
				this.changingAuthIndex = -1;
				this.isChangingAuth = false;

			}, 1500);
		},

		validateEmail: function() {
			this.emailErr = false;
			let emailSplit = this.inputEmail.split('@');
			if (emailSplit.length !== 2) {
				this.emailErr = true;
				return 'error';
			}

			let domain = emailSplit[1].split('.')
			if (domain.length !== 2) {
				this.emailErr = true;
				return 'error';
			}

			return this.inputEmail;
		},

		sendReqest: async function(data, route = '') {
			const idToken = await auth.currentUser.getIdToken();
			const authToken = `Bearer ${idToken}`;

			const requestOptions = {
				method: 'POST',
				url: process.env.VUE_APP_TEAM_URL + '/' + route,
				data: data,
				headers: {
					'Authorization': authToken,
					'Content-Type': 'application/json'
				},
				timeout: 10000,
			};

			let isError = false;
			const res = await axiosIPC(requestOptions).catch(() => { isError = true; });
			if (isError) {
				return {
					error: {
						type: 'connection-error',
						message: 'Issues contacting server. Please check your connection and try again.',
					}
				}
			}
			return res.data;
		},

		clearRemoveMember: function() {
			this.isRemovingMember = false;
			this.removeMemberIndex = 0;
			this.removeMemberStatus = 0;
		},

		clearUpdateMember: function() {
			if (this.updateStatus === 1) { this.inputEmail = ''; }
			this.isUpdating = false;
			this.updateStatus = 0;
		},

		changePerm: function(email, permissions) {
			if (email === this.user.email) { return; }
			this.inputEmail = email;
			this.inputPermissions = permissions;

			//Focus in on list selector
			this.$nextTick(() => {
				this.$refs.emailinput.children[0].children[1].focus();
			});
		},
	},
};
</script>
<style>
.ts__panel {
	display: flex;
	flex-direction: column;
	min-width: 70rem;
	width: 70rem;
	height: 100vh;
	overflow: hidden;
	margin: 2rem auto;
	padding: 1.5rem;
}

.ts__inputs {
	display: flex;
}

@media(max-width: 650px) {
	.ts__inputs {
		flex-direction: column;
	}
}

.ts__panel__message {
	display: block;
	min-height: 3rem;
	overflow: hidden;
	padding: 0 .5rem;

	line-height: 3rem;
	text-align: left;
}

.ts__member__cont {
	max-height: 40rem;

	overflow-y: auto;
}

.ts__member {
	display: flex;
	padding: .5rem;
	border-bottom: .1rem solid var(--color-bg-secondary-light);
	justify-content: space-between;
}

.ts__member__header {
	display: flex;
	justify-content: space-between;
	margin-top: 2rem;

	border-bottom: .1rem solid var(--color-bg-secondary-light);
}

@media(max-width: 500px) {
	.ts__member {
		flex-wrap: wrap;
		padding: .5rem 1%;
	}

	.ts__member>* {
		max-width: 30%;
		margin: 0 !important;
	}

	.ts__member__text {
		text-align: left;
	}

	.ts__member>div:first-child {
		min-width: 100%;
	}

	.ts__member__header {
		flex-direction: column;
	}
}

.ts__member__header-label {
	display: flex;
	flex-direction: column;
	align-content: flex-end;
	width: 7.5rem;
	margin: 1rem .5rem;

	line-height: 1rem;
	font-weight: 700;
}

.ts__member__header-label::before {
	content: '';
	flex: 1;
}

.ts__member__image {
	width: 4rem;
	height: 4rem;
	border-radius: 2rem;
	overflow: hidden;
}

.ts__member__text {
	flex: 1;
	line-height: 2rem;
	font-size: 1.5rem;
	padding: 1rem;
}

.ts__member__loading-icon {
	height: 3rem;
	padding: 0 2rem;

	stroke: var(--color-error);
}

.ts__license-icon {
	height: 3rem;
	width: 3rem;
	stroke: var(--color-primary);
	margin: auto;
}

/*----------------*/



.ts__dz__header {
	border: none;
	display: block;
	height: 3rem;
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 2rem;
	cursor: pointer;
	color: var(--color-error);
}

.ts__dz {
	border: .1rem solid var(--color-error);
	padding: 1rem;
	transition: .4s;
	max-height: 8.5rem;
	border-radius: .3rem;
	overflow: hidden;
}

.ts__dz--clicked {
	max-height: 4rem;
	overflow: hidden;
	transition: .4s ease;
}

@media(max-width: 700px) {
	.ts__panel {
		width: 100%;
		max-width: 100%;
		min-width: 100%;
		height: auto;
		margin: 0;
		min-height: 30rem;
		border-radius: 0 !important;
		border-bottom: .1rem solid var(--color-bg-secondary-light);
		box-shadow: none !important;
		padding: 1rem;
	}
}
</style>
<template>
	<div class="flex-1 flex-column">
		<div class="stripe-cart__header bg-gradient">
			Subscription
		</div>
		<div v-show="!submitReady" class="flex-1 flex-column vld-parent">
			<loading :active="isSubmitting" :is-full-page="false" :background-color="$colorBackground" :color="$colorPrimary"></loading>
			<div class="flex-1">
				<div class="stripe-cart__item">
					<DirButtonCont v-if="!submitConfirmed" margin="2rem 0 0 0">
						<template v-slot:slot-1>
							<ListSelector label="Plan" :itemList="selectorList" :width="'20rem'" v-model="planIndex" />
						</template>
						<template v-slot:slot-2>
							<div class="flex-1"></div>
						</template>
						<template v-slot:slot-3>
							<ListSelector label="Seats" :itemList="qtyList" :width="'6rem'" v-model="licenseQty" />
						</template>
					</DirButtonCont>
					<!-- </div> -->
				</div>
				<div class="stripe-card__item flex-row" style="stroke: var(--color-primary)">
					<TextInputMD name="Discount Code" width="12rem" v-model="discountCode" @submit="checkDiscount($event)" :error="codeError" />
					<IconValidate size="3rem" :loading="checkingDiscount" margin="2rem 0 0 .5rem" />
				</div>
				<div class="stripe-cart__item flex-1">
					<div v-if="isDiscount" class="flex-row flex-space">
						<span class="stripe-cart__line-cost">Price</span>
						<span class="stripe-cart__line-cost stripe-cart__line-cost--strike">{{ currencyFormat(subtotal/(1-this.discount.percent_off/100)) }}</span>
					</div>
					<div class="flex-row flex-space">
						<span class="stripe-cart__line-cost">Subtotal</span>
						<span class="stripe-cart__line-cost">{{ currencyFormat(subtotal) }}</span>
					</div>
					<div class="flex-row flex-space" v-for="(item, index) in userTaxes" :key="'stripe-card-taxes-' + index">
						<span class="stripe-cart__line-cost" :key="'stripe-card-taxes-' + index">{{ item.display_name + ' ' + item.percentage }}%</span>
						<span class="stripe-cart__line-cost">{{ currencyFormat(item.percentage/100*subtotal) }}</span>
					</div>
				</div>
				<div class="stripe-cart__item flex-1">
					<h6 class="push-right">{{currencyFormat(totalPrice)}}</h6>
					<p class="push-right"> per {{planInterval}}</p>
				</div>
			</div>
			<div>
				<div class="no-overflow">
					<transition name="slide-out">
						<div class="stripe_notifications" v-if="message !== ''">{{message}}</div>
					</transition>
				</div>
				<DirButtonCont v-if="!isSubmitting" margin="1rem 0 0 0">
					<template v-slot:slot-1>
						<ButtonStandard name="Cancel" @click="$emit('click-cancel')" :error="true" :flex="true" />
					</template>
					<template v-slot:slot-2>
						<ButtonStandard name="Submit" @click="submitReady = true" :flex="true" />
					</template>
				</DirButtonCont>
			</div>
			<div v-if="submitConfirmed" class="stripe-item-confirmed">
				<div class="stripe-item-confirmed__message">
					<h6>Subscription Completed</h6>
					<br>
					<ButtonStandard name="Next" @click="$emit('click-next')" />
				</div>
			</div>
		</div>
		<div v-show="submitReady" class="stripe-confirm" style="text-align: left">
			<h6>Refunds:</h6>
			<p>No refunds are offered for subscriptions after 14 days. </p>
			<br>
			<h6>Cancellation:</h6>
			<p>You can cancel your subscription at any time from the Account Management Dashboard. Your subscription will operate until the start of your next billing period.</p>
			<br>
			<h6>Terms and Conditions</h6>
			<p>By completing payment you agree to the website <a :href="termsURL" target="_blank">Terms and Conditions</a> and our <a :href="privacyURL" target="_blank">Privacy Policy</a></p>
			<div class="stripe-cart__item flex-1" style="border: none">
				<p class="push-right">{{licenseQty + 1 +' licenses'}}</p>
				<h6 class="push-right">{{currencyFormat(totalPrice)}}</h6>
				<p class=" push-right"> per {{planInterval}}</p>
			</div>
			<DirButtonCont>
				<template v-slot:slot-1>
					<ButtonStandard name="Cancel" @click="submitReady = false" :error="true" flex="true" />
				</template>
				<template v-slot:slot-2>
					<ButtonStandard name="Complete Payment" @click="createUpdateSub" :flex="true" />
				</template>
			</DirButtonCont>
		</div>
	</div>
</template>
<script>
import ListSelector from "@/components/functional/ListSelector.vue";
import ButtonStandard from "@/components/functional/ButtonStandard.vue";
import TextInputMD from "@/components/functional/TextInputMD.vue";
import DirButtonCont from "@/components/functional/DirButtonCont.vue";
import IconValidate from "@/components/icons/IconValidate.vue";

import { auth } from "@/firebaseConfig.js";
import { mapState, mapActions, mapGetters } from "vuex";
import axios from "axios";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const debug = false;

export default {
	name: 'StripeCart',
	props: {
		stripe: Object,
		defaultTaxCode: String,
	},
	components: {
		ListSelector,
		ButtonStandard,
		Loading,
		DirButtonCont,
		TextInputMD,
		IconValidate
	},

	data() {
		return {
			termsURL: process.env.VUE_APP_TERMS_PAGE,
			privacyURL: process.env.VUE_APP_PRIVACY_PAGE,

			isSubmitting: false,
			submitConfirmed: false,

			submitReady: false,

			checkingDiscount: false,
			discountCode: '', //Input code
			discount: {}, //Discount object
			isDiscount: false,
			codeError: false,

			//Error messages
			message: '',
			messageTimeout: '',

			euroList: ['AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE'],

			licenseQty: 1,
			planIndex: 0,

			currency_div: 100,
		};
	},

	computed: {
		...mapState('stateViewLev', ['user', 'claims', 'userInfo', 'stripePlanList', 'stripeTaxList']),
		...mapGetters('stateViewLev', ['getViewData']),
		//List of all Stripe plans in Firestore
		planList: function() {
			return this.stripePlanList.filter((item) => {
				if (item.currency.toUpperCase() === this.userCurrency.toUpperCase() && item.active) {
					return item;
				}
			});
		},

		//Reduce plan list to names for display
		selectorList: function() {
			let list = this.planList.map((item) => {
				return item.nickname
			});
			return list;
		},

		//List of possible seat quantities
		qtyList: function() {
			const qtyList = [];
			for (let i = 1; i <= 25; i++) {
				qtyList.push(i);
			}
			return qtyList;
		},

		//Get user tax code from billing address or ipGeo
		userTaxCode: function() {
			if (this.user && this.claims.stripe_tax) {
				return this.claims.stripe_tax;
			} else {
				return this.defaultTaxCode;
			}
		},

		//Tax codes that apply to user locale
		userTaxes: function() {
			let userCountry = this.userTaxCode.split('-')[0]
			let userTaxes = [];

			//Set all Euro countries to German tax rate
			if (this.euroList.includes(userCountry)) { userCountry = 'EURO'; }
			//Add the tax rate matched to each country code and region
			this.stripeTaxList.forEach((item) => {
				if (item.jurisdiction === userCountry ||
					item.jurisdiction === this.userTaxCode) {
					userTaxes.push(item);
				}
			});

			return userTaxes;
		},

		//User currency code based on tax country
		userCurrency: function() {
			if (this.userInfo && this.userInfo.currency != null) { return this.userInfo.currency; }

			let userCountry = this.userTaxCode.split('-')[0];

			if (userCountry === 'CA') { return 'CAD'; }
			if (userCountry === 'GB') { return 'GBP'; }
			if (this.euroList.includes(userCountry)) { return 'EUR'; }

			//Else:
			return 'USD'
		},

		planInterval: function() {
			if (typeof this.planList[this.planIndex] !== 'undefined') {
				return this.planList[this.planIndex].interval;
			} else {
				return '';
			}
		},

		//Calculate subtotal based on plan and qty
		subtotal: function() {
			const plan = this.planList[this.planIndex];
			if (debug) { console.log(this.planList); }
			if (!plan) { return; }
			let qty = this.licenseQty + 1; //Compensate for zero indexing
			let subtotal = 0;
			if (plan.billing_scheme === 'per_unit') {
				subtotal = qty * plan.amount / this.currency_div;
			}

			let prevTier = 0;
			let tierSpan = 0;
			if (plan.billing_scheme === 'tiered') {
				plan.tiers.forEach((tier) => {
					//Find the quantity that the tier spans. If the remaining
					//	quantity is greater than this then get the span lump sum
					// 	If the remaining quanity is less than the span then you are 
					//	in the last tier so just multiply by the tier price.
					if (tier.up_to) { tierSpan = tier.up_to - prevTier; }
					if (tier.up_to && qty >= tierSpan) {
						subtotal += tierSpan * tier.unit_amount / this.currency_div;
						qty -= tierSpan;
						prevTier = tier.up_to;
					} else {
						subtotal += qty * tier.unit_amount / this.currency_div;
						qty = 0;
					}
				})
			}

			if (this.isDiscount) {
				subtotal = subtotal * (1 - this.discount.percent_off / 100);
			}
			return subtotal;
		},

		totalPrice: function() {
			let taxSum = 0;
			this.userTaxes.forEach((tax) => {
				taxSum = taxSum + tax.percentage / 100 * this.subtotal;
			});

			return this.subtotal + taxSum;
		},
	},

	methods: {
		...mapActions('stateViewLev', ['setViewData', 'resetUser']),

		setMessage: function(message) {
			clearTimeout(this.messageTimeout)
			this.message = message;
			this.messageTimeout = setTimeout(() => {
				this.message = '';
			}, 7000)
		},

		//Subscribe stripe customer with payment method
		createUpdateSub: async function() {
			if (this.isSubmitting) { return; }
			this.isSubmitting = true;
			this.submitReady = false;

			const idToken = await auth.currentUser.getIdToken();
			const authToken = `Bearer ${idToken}`;

			//Items is an array of plans with associated data
			//	Multi plan subscriptions will be supported with 
			// 	this arrangement by adding additional items
			const requestData = {
				items: [{
					plan: this.planList[this.planIndex].id,
					quantity: this.licenseQty + 1,
				}],
				coupon: ''
			};

			//If needed add discount code
			if (this.isDiscount) {
				requestData.coupon = this.discount.id;
			}

			const requestOptions = {
				method: 'POST',
				data: requestData,
				url: process.env.VUE_APP_STRIPE_URL + '/subscribe',
				headers: {
					'Authorization': authToken,
					'Content-Type': 'application/json'
				},
				timeout: 10000,
			};

			let isError = false;
			const res = await axios(requestOptions).catch(() => { isError = true; });
			if (isError) {
				this.isSubmitting = false;
				this.setMessage('Issues contancting the server. Please check your connection and try again.')
				return;
			}

			if (debug) { console.log(res.data); }
			//Check subscriptions errors
			if ('error' in res.data) {
				this.setMessage(res.data.error.message)
				this.isSubmitting = false;
				return;
			}

			//Subscription will always be returned
			const subscription = res.data.subscription;
			//Payment intent will always be returned
			const paymentIntent = subscription.latest_invoice.payment_intent;
			if (debug) { console.log(subscription); }

			//Check that the payment intent is not null (for invoicing)
			if (paymentIntent) {
				if (paymentIntent.status === 'requires_action') {
					if (debug) { console.log('validate payment'); }
					const confirmStatus = await this.stripe.confirmCardPayment(paymentIntent.client_secret)
					if ('error' in confirmStatus) {
						this.setMessage(confirmStatus.error.message)
						this.isSubmitting = false;
						return;
					}
					if (debug) { console.log(confirmStatus); }
				}
				// if (paymentIntent.status === 'requires_payment_method') {
				// 	this.setMessage('You payment information on file requires attention. Please update and authenticate if needed. Account changes cannot be made unless a payment method is on file.')
				// }
			}
			this.setMessage('Subscription was updated successfully.')
			this.submitConfirmed = true;
			this.isSubmitting = false;
		},

		currencyFormat: function(input) {
			return new Intl.NumberFormat('en-IN', { style: 'currency', currency: this.userCurrency }).format(input);
		},

		checkDiscount: async function(code) {
			if (this.checkingDiscount) { return; }
			if (this.discountCode === '') { return; }
			this.checkingDiscount = true;
			this.codeError = false;
			this.isDiscount = false;

			const idToken = await auth.currentUser.getIdToken();
			const authToken = `Bearer ${idToken}`;

			const requestData = {
				code: code,
			};
			const requestOptions = {
				method: 'POST',
				data: requestData,
				url: process.env.VUE_APP_STRIPE_URL + '/discount',
				headers: {
					'Authorization': authToken,
					'Content-Type': 'application/json'
				},
				timeout: 10000,
			};

			let isError = false;
			const res = await axios(requestOptions).catch(() => { isError = true; });
			if (isError) {
				this.checkingDiscount = false;
				this.setMessage('Issues contancting the server. Please check your connection and try again.')
				return;
			}

			if ('error' in res.data) {
				this.setMessage(res.data.error.message);
				this.discount = {};
				this.codeError = true;
			} else {
				this.discount = res.data;
			}

			//Insure discount data is valid
			if ('percent_off' in this.discount && this.discount.percent_off) {
				this.isDiscount = true;
			} else {
				this.setMessage('Invalid discount code.');
				this.discount = {};
				this.codeError = true;
			}

			if (debug) { console.log(res.data); }
			this.checkingDiscount = false;
		}
	},
}
</script>
<style>
.stripe-cart {
	height: 100%;
	width: 100%;
	overflow-y: auto;
}

.stripe-cart__header {
	width: 100%;
	height: 5rem;
	line-height: 5rem;
	font-size: 2rem;
	font-weight: 700;
	text-align: left;
	padding: 0 2rem;
	background-color: var(--color-primary);
	color: var(--color-font-primary);
	/*border: .1rem solid var(--color-primary);*/
	border-radius: .5rem;
}

.stripe-cart__item {
	min-height: 5rem;
	border-bottom: .1rem solid var(--color-bg-secondary-light);
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: .5rem 0;
}

.stripe-cart__item h6 {
	padding: 0;
	padding-top: .5rem;
}

.stripe-cart__line-cost {
	font-size: 1.5rem;
	margin: .5rem;
}

.stripe-cart__line-cost--strike {
	text-decoration: line-through;
}

.stripe-confirm {
	flex: 1;
	display: flex;
	flex-direction: column;
	position: relative;
}

.stripe-confirm>h6 {
	font-size: 1.7rem;
	font-weight: 600;
	padding: 0;
	margin-top: 1rem;
}

.stripe-confirm a {
	font-size: 1.3rem;
	text-decoration: underline;
	cursor: pointer;
}

@media(max-width: 500px) {
	.stripe-cart__item {
		padding: .5rem 0;
	}
}
</style>
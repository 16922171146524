'use strict';

import store from "@/store";
import { validateBikeObj } from "@/modules/validateFile.js";

export async function openFile() {
	if (!store.state.stateViewLev.user) { return; }
	const fileObjects = await window.ipcRenderer.invoke('open-files');

	if (!fileObjects) { return; }

	//Check each parsed file from jetpack and try to load
	fileObjects.forEach((file) => {
		if ('error' in file) {
			store.dispatch('stateViewLev/setError', `${file.path} is invalid.`);
			return;
		}
		const bikeObj = validateBikeObj(file.data);
		if (bikeObj === false) {
			store.dispatch('stateViewLev/setError', `File is corrupted.`);
			return;
		}

		bikeObj.filePath = file.path;
		bikeObj.database = 3; //Set DB to local
		store.dispatch('stateBikeData/loadDBBike', bikeObj);
	});
}

export async function saveFile(event, forcePath = false) {
	if (store.state.stateBikeData.bikeDataArray.length < 1) { return; }
	const bike = store.state.stateBikeData.bikeDataArray[store.state.stateBikeData.selectedBikeIndex];
	// console.log(bike.filePath)
	// console.log(bike.database)
	// console.log(forcePath)
	if (bike.database === 3 || forcePath) {
		if (bike.filePath && !forcePath) {
			await awaitSave(bike); //If the path exists and bike is set to local then overwrite directly
		} else {
			const pathRes = await window.ipcRenderer.invoke('dialog', 'save')
			if (pathRes && pathRes.canceled) { return; } //Do nothing for dialog cancel
			bike.filePath = pathRes.filePath; //Set filepath into bike
			if (forcePath) { bike.database = 3; } //Allows save-as for DB bikes
			await awaitSave(bike);
		}
	} else {
		await invalidCursor();
	}
}

async function awaitSave(object) {
	document.body.classList.add('busy-cursor');
	await window.ipcRenderer.invoke('save-file', object);
	document.body.classList.remove('busy-cursor');
}

async function invalidCursor() {
	document.body.classList.add('error-cursor');
	await sleep(250);
	document.body.classList.remove('error-cursor');
}

function sleep(ms) { return new Promise(resolve => setTimeout(resolve, ms)); }
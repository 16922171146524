<template>
	<div class="landing--auth" key="emailvalidate">
		<div class="landing__card--auth card-shadow">
			<div class="landing__login-image">
				<div class="landing__login-icon card-shadow">
					<AppIcon type="login" />
				</div>
			</div>
			<h5 class="">Please confirm your email address</h5>
			<p class="margin--top-bottom">{{ emailMessage }}</p>
			<ButtonStandard name="Resend Verification Email" :btnstyle="{width: 'auto', padding: '0 1rem'}" @click="sendEmailVerification" />
		</div>
	</div>
</template>
<script>
import AppIcon from "@/components/icons/AppIcon.vue";
import ButtonStandard from "@/components/functional/ButtonStandard.vue";

import { fs_email_validate } from "@/firebaseConfig.js";

export default {
	name: 'LadingEmailVerify',
	components: {
		ButtonStandard,
		AppIcon,
	},

	data() {
		return {
			emailMessage: '',
		}
	},

	methods: {
		setEmailMessage: function(msg) {
			this.emailMessage = msg;
			setTimeout(() => { this.emailMessage = '' }, 10000);
		},

		sendEmailVerification: async function() {
			const res = await fs_email_validate();
			if (res === 'success') {
				this.setEmailMessage('Verification Email sent.')
				return;
			}
			if (res === 'auth/too-many-requests') {
				this.setEmailMessage('Too many requests. Please check your spam folder or contact support. ')
				return;
			} else {
				this.setEmailMessage('Issues contacting server. Please check your connection or contact support. ')
			}
		},
	}
}
</script>
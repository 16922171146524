<template>
	<div ref="list" 
	class="listselect-list" 
	tabindex="0" 
	@focusin="handleFocus" 
	@focusout="handleBlur" 
	@keydown.down.prevent="selectNext" 
	@keydown.up.prevent="selectPrev" 
	@keydown.enter="handleEnter" 
	:style="{minWidth: width, maxWidth: width, margin: margin}">
		<div class="listselect-result-list">
			<div class="listselect-result-list__items" :style="{maxHeight: maxNumItems ? maxNumItems*2.5 + 'rem' : '15rem'}">
				<!-- <transition-group name="slide-out"> -->
				<button class="listselect-result"
				ref="buttonlist" 
				v-for="(result, index) in itemList" 
				:key="'listselect-list-'+index"  
				@keydown.enter.stop 
				@click="selectItem($event, index)" 
				@touchend="selectItem($event, index)">
					{{ result }}
				</button>
			</div>
		</div>
		<label v-if="label" class="listselect__label" for="">{{ label }}</label>
		<button ref="mainbtn" class="listselect__input" @focus="inputFocus" @click="inputFocus">
			<span class="listselect__input--text" @touchend="$refs.mainbtn.focus()">{{itemList[value]}}</span>
			<div class="listselect__arrow_icon" @touchend="$refs.mainbtn.focus()">
				<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
					<path d="M33.17 17.17l-9.17 9.17-9.17-9.17-2.83 2.83 12 12 12-12z" />
					<path d="M0 0h48v48h-48z" fill="none" /></svg>
			</div>
		</button>
		<!--  -->
	</div>
</template>
<script>
export default {
	name: "listselectFull",
	components: {},

	props: {
		itemList: Array,
		width: String,
		value: Number,
		flex: Boolean,
		margin: String,
		label: String,
		error: Boolean,
		maxNumItems: Number,
	},

	data() {
		return {
			results: [], //Adds classes based on state

			valueAtFocus: '', //Initial value

			focusedItem: -1,
		}
	},


	methods: {
		//On list select emit a submit event
		selectItem: function(event, item) {
			this.$emit('input', item);
			this.$emit('submit', item);
			event.target.blur();
			this.$refs.list.blur();
		},

		//Pass input event to parent
		handleInput: function(event) {
			this.$emit('input', event.target.value);
		},

		//Record initial value at focus
		//	When focusing out we can compare and 
		//	Decide whether or not to emit a submit
		handleFocus: function() {
			this.valueAtFocus = this.value;
		},

		inputFocus: function(event) {
			event.target.focus();
			this.focusedItem = -1;
		},

		//Emit a submit only if value has changed
		handleBlur: function() {
			if (this.valueAtFocus !== this.value) {
				this.$emit('input', this.value);
				this.$emit('submit', this.value);
			}
			this.$emit('refocus')
		},

		handleEnter: function(event) {
			if (this.valueAtFocus !== this.value) {
				this.$emit('input', this.value);
				this.$emit('submit', this.value);
			}
			event.target.blur();
			this.$refs.list.blur();
		},

		selectNext: function() {
			if (this.focusedItem < this.itemList.length - 1) {
				this.focusedItem++;
			}
			this.$refs.buttonlist[this.focusedItem].focus();
		},

		selectPrev: function() {
			if (this.focusedItem < 0) { return; }
			if (this.focusedItem !== 0) { this.focusedItem--; }
			this.$refs.buttonlist[this.focusedItem].focus();
		},

		clearInput: function() {
			this.$emit('input', '');
			this.$emit('submit', '');
		}
	},

};
</script>
<style>
.listselect-list {
	display: block;
	height: 4rem;
	flex: 1;
	position: relative;
}

.listselect__input {
	height: 3rem;
	width: 100%;
	z-index: 10;
	padding: .2rem 2.5rem .2rem 1rem;

	background-color: var(--color-bg);
	border: none;
	border: 0.1rem solid var(--color-bg-secondary-light);
	border-radius: .3rem;

	line-height: 2rem;
	text-align: left;
}

.listselect__input--text {
	position: absolute;
	top: .5rem;
	left: 1rem;

	cursor: pointer;
}

/*Initial item must be moved slightly on focus*/
.listselect-list:focus-within>.listselect__input {
	border-radius: 0;
	border: none;
	border-bottom: .7px dotted var(--color-primary);

	z-index: 11;
}


.listselect__arrow_icon {
	height: 2.5rem;
	width: 2.5rem;
	position: absolute;
	right: .3rem;
	top: .3rem;

	background: transparent;
	fill: var(--color-font-bg);
	cursor: pointer;

	transition: .5s ease;
}


.listselect__label {
	position: absolute;
	top: -1.5rem;
	left: .3rem;
	display: inline;
	z-index: 2;
	color: var(--font-bg);
	background: transparent;
	font-size: 1.1rem;
	font-weight: 700;
}


/* Result list styling. See AutocompleteList for comparison*/
.listselect-result-list {
	visibility: hidden;
	box-sizing: border-box;
	width: 100%;
	position: absolute;
	z-index: 10;
	margin: 0;
	padding: 0;
	/*padding: .1px;*/
	overflow: hidden;
	top: 0rem;

	border: .1rem solid var(--color-primary);
	border-radius: .3rem;
	background: transparent;
	list-style: none;

	box-shadow: 0;
	transition: box-shadow .3s;
}

.listselect-result-list__items {
	width: 100%;
	margin-top: 3rem;
	max-height: 15rem;
	border-radius: .15rem;
	overflow-x: hidden;
	overflow-y: auto;
	background-color: var(--color-bg);
}

.listselect-list:focus-within .listselect-result-list {
	visibility: visible;
	box-shadow: 0 0 0 3px var(--color-primary-light);
}

.listselect-list:focus-within .listselect__label {
	color: var(--color-primary);
}

.listselect-list:focus-within .listselect__arrow_icon {
	transform: rotate(180deg);
	transition: .5s ease;
}

/* Single result item */
.listselect-result {
	display: block;
	width: 100%;
	height: 2.5rem;
	padding: .5rem .5rem .5rem 1rem;

	background: transparent;
	border: none;
	cursor: default;

	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	text-align: left;
}

.listselect-result:hover,
.listselect-result:focus {
	background-color: var(--color-bg-cell-highlight);
}
</style>
<template>
	<transition @enter="showContent = true" name="modal-opacity">
		<div class="modal-mask" v-if="show">
			<transition name="scale" @leave="$emit('close')">
				<div class="modal-container" v-if="showContent" :style="{ width: width ? width : '', maxWidth: maxWidth, minWidth: minWidth, padding: padding }" :data-test="test">
					<div class="modal-header flex-row" :style="headerStyle">
						<slot name="header">
							<p></p>
						</slot>
						<button v-if="!noClose" class="btn modal-default-button" @click="showContent=false" data-test="btn-close">
							<IconClose />
						</button>
					</div>
					<div class="modal-body" :class="{'modal-body__scroll' : scroll}">
						<slot name="body">
							default body
						</slot>
					</div>
					<div class="modal-footer">
						<slot name="footer">
						</slot>
					</div>
				</div>
			</transition>
		</div>
	</transition>
</template>
<script>
import IconClose from "@/components/icons/IconClose.vue";

export default {
	name: "ModalWindow",
	props: {
		show: Boolean,
		width: String,
		maxWidth: String,
		minWidth: String,
		padding: String,
		noClose: Boolean,
		hideContent: Boolean,
		scroll: Boolean,
		test: String,
		headerStyle: Object,
	},
	components: {
		IconClose,
	},

	data() {
		return {
			showContent: false,
		}
	},

	watch: {
		hideContent: function(val) {
			if (val) { this.showContent = false; }
		},
		show() {
			if (this.show === false) {
				window.removeEventListener("keyup", this.onEscapeKeyUp);
			} else {
				window.addEventListener("keyup", this.onEscapeKeyUp);
			}
		}
	},

	methods: {
		onEscapeKeyUp: function(event) {
			if (event.which === 27) { this.showContent = false; }
		}
	}
}
</script>
<style>
.modal-mask {
	position: fixed;
	z-index: 998;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;

	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1rem;
	overflow: hidden;

	background-color: rgba(0, 0, 0, 0.5);
	transition: opacity .2s linear;
}

.modal-container {
	display: flex;
	flex-direction: column;
	max-width: 80vw;
	max-height: 100%;
	flex: 1;
	margin: auto;
	padding: 2rem;

	background-color: var(--color-bg);
	border-radius: .2rem;
	box-shadow: 0 .2rem .8rem rgba(0, 0, 0, 0.33);

}

.modal-header {
	padding: 0 .5rem;
	justify-content: space-between;
	padding-bottom: 1rem;
}

.modal-body {
	padding: .5rem;
	flex: 1;
	display: flex;
	flex-direction: column;
	/*justify-content: center;*/
	overflow: hidden;
}

.modal-body__scroll {
	overflow-y: auto;
}

.modal-default-button {
	height: 2rem;
	width: 2rem;
	overflow: hidden;

	border: none;
	border-radius: .25rem;
	background-color: var(--color-bg);
	fill: var(--color-font-bg);
	float: right;

	transition: .3s;
}

.modal-default-button:hover {
	float: right;
	border: none;
}

/*-----------------*/
.modal-opacity-enter {
	opacity: 0;
}

.modal-opacity-leave-to {
	opacity: 0;
}

@media(max-width: 500px) {
	.modal-container {
		position: absolute;
		top: 0;
		left: 0;
		min-width: 100vw !important;
		max-width: 100vw !important;
		height: 100vh;
		padding: 15px 10px !important;
		margin: 0;

		border-radius: 0;

		overflow: hidden;
		/*overflow-y: auto;*/
	}

	.modal-body {
		overflow-y: auto;
	}
}
</style>
<template>
	<div class="flex-1 flex-column">
		<!-- Page Header -->
		<div class="stripe-cart__header bg-gradient flex-row flex-space">
			<span>Payment Details</span>
			<div v-if="!isMobile" class="stripe-billing-logo">
				<StripeLogo />
			</div>
		</div>
		<!-- Input form and submit buttons -->
		<div class="flex-1 flex-column vld-parent">
			<loading :active="creatingPayment" :is-full-page="false" :background-color="$colorBackground" :color="$colorPrimary"></loading>
			<div class="stripe-billing-section push-right padding--top">
				<ListSelector label="Select Payment Method:" :itemList="paymentMethodDisplayList" :width="'15rem'" v-model="paymentIndex" @submit="setPaymentMethod" />
			</div>
			<div class="flex-1 scroll-y">
				<div class="stripe-form__double-input">
					<TextInputST name="Email" :extmargin="true" v-model="email" :error="errList.email" />
					<div class="stripe-form__double-divider"></div>
					<TextInputST name="name" placeholder="Name on Card" :extmargin="true" v-model="name" :error="errList.name" />
				</div>
				<TextInputST name="address" placeholder="Address Line 1" v-model="address1" :error="errList.address1" />
				<TextInputST name="Address Line 2" v-model="address2" />
				<TextInputST name="city" placeholder="City" v-model="city" :error="errList.city" />
				<div class="stripe-form__double-input">
					<template v-if="hasStateInAddress">
						<TextInputST name="Province/State" :extmargin="true" v-model="region" @submit="	regionSubmit" :error="errList.region" />
						<div class="stripe-form__double-divider"></div>
					</template>
					<TextInputST name="Country" :extmargin="true" v-model="country" @submit="countrySubmit" :error="errList.country" />
				</div>
				<TextInputST v-if="paymentMethod === 'invoice'" name="Postal/Zip Code" v-model="postalCode" />
				<!-- Stripe Card Element -->
				<div v-show="paymentMethod === 'card'" class="stripe__card" ref="stripecard"></div>
				<!-- <div class="stripe__card" ref="stripeiban"></div> -->
			</div>
			<div>
				<!-- Information for Invoice Billing -->
				<span v-if="paymentMethod === 'invoice'" class="stripe_info">Email invoices will be sent. All invoices can be accessed from your account dashboard. </span>
				<!--  -->
				<div class="no-overflow">
					<transition name="slide-out">
						<div class="stripe_notifications" v-if="message !== ''">{{message}}</div>
					</transition>
				</div>
				<DirButtonCont v-if="!creatingPayment" margin=".5rem 0 0 0">
					<template v-slot:slot-1>
						<ButtonStandard name="Cancel" @click="$emit('click-cancel')" :error="true" :flex="true" />
					</template>
					<template v-slot:slot-2>
						<ButtonStandard name="Confirm Payment Info" @click="createUpdatePayment(paymentMethod)" :flex="true" />
					</template>
				</DirButtonCont>
			</div>
			<!-- Success Message -->
			<div v-if="paymentConfirmed" class="stripe-item-confirmed">
				<div class="stripe-item-confirmed__message">
					<h6>Payment Succesfully Updated</h6>
					<br>
					<ButtonStandard name="Next" @click="$emit('click-next')" />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import StripeLogo from "@/components/functional/StripeLogo.vue";
import TextInputST from "@/components/functional/TextInputST.vue";
import ListSelector from "@/components/functional/ListSelector.vue";
import ButtonStandard from "@/components/functional/ButtonStandard.vue";
import DirButtonCont from "@/components/functional/DirButtonCont.vue";

import axios from "axios";
import { auth } from "@/firebaseConfig.js";
import { mapState, mapActions } from "vuex";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const debug = false;
export default {
	name: 'StripeBilling',
	props: {
		stripe: Object,
	},

	components: {
		StripeLogo,
		TextInputST,
		ListSelector,
		ButtonStandard,
		Loading,
		DirButtonCont,
	},

	data() {
		return {
			card: {},
			creatingPayment: false,
			paymentConfirmed: false,

			paymentMethodDisplayList: ['Card', 'Invoice'],
			paymentMethodList: ['card', 'invoice'],
			paymentIndex: 0,
			paymentMethod: '',

			//Error messages
			message: '',
			messageTimeout: '',

			//Form Data and Errors
			email: '',
			name: '',
			address1: '',
			address2: '',
			city: '',
			region: '',
			region_alpha_2: '',
			country: '',
			country_alpha_2: '',
			postalCode: '',

			errList: {
				name: false,
				email: false,
				address1: false,
				city: false,
				country: false,
				region: false,
			},
		};
	},

	mounted() {
		this.configureStripe();
		if (this.user) {
			this.email = this.user.email;
			this.name = this.user.displayName;
		}

		this.paymentMethod = this.paymentMethodList[this.paymentIndex];
	},

	computed: {
		...mapState('stateViewLev', ['isMobile']),
		user: function() { return auth.currentUser; },
		hasStateInAddress: function() { return ['us', 'ca', 'au', 'mx', 'it'].includes(this.country_alpha_2.toLowerCase())
		}
	},

	methods: {
		...mapActions('stateViewLev', ['setViewData', 'resetUser']),

		setMessage: function(message) {
			clearTimeout(this.messageTimeout)
			this.message = message;
			this.messageTimeout = setTimeout(() => {
				this.message = '';
			}, 7000)
		},

		setPaymentMethod: function(index) {
			this.paymentMethod = this.paymentMethodList[index];
		},

		//Configure stripe payment options (card ect.)
		configureStripe: function() {
			const cardOptions = {
				style: {
					base: {
						color: '#32325d',
						fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
						fontSmoothing: 'antialiased',
						fontSize: '15px',
						'::placeholder': {
							color: '#D3D2D2'
						},
					},
					invalid: {
						color: '#fa755a',
						iconColor: '#fa755a'
					}
				}
			};
			if (this.isMobile) { cardOptions.style.base.fontSize = '20px'; }

			// const ibanOptions = {
			// 	style: style,
			// 	supportedCountries: ['SEPA'],
			// 	// Elements can use a placeholder as an example IBAN that reflects
			// 	// the IBAN format of your customer's country. If you know your
			// 	// customer's country, we recommend that you pass it to the Element as the
			// 	// placeholderCountry.
			// 	placeholderCountry: 'DE',
			// };

			this.card = this.stripe.elements().create('card', cardOptions);
			// this.iban = this.stripe.elements().create('iban', ibanOptions);

			this.card.mount(this.$refs.stripecard);
			// this.iban.mount(this.$refs.stripeiban);

			//Assign card postal code to address info
			this.card.addEventListener('change', (event) => {
				this.postalCode = event.value.postalCode;
			});
		},

		//Create stripe payment method with card
		createCardPaymentMethod: async function() {
			const paymentSetup = {
				type: 'card',
				card: this.card,
				billing_details: {
					email: this.email,
					name: this.name,
					address: {
						line1: this.address1,
						line2: this.address2,
						city: this.city,
						state: this.region_alpha_2,
						country: this.country_alpha_2,
						postal_code: this.postalCode,
					}
				},
				metadata: {
					uid: auth.currentUser.uid,
					app: 'synfire-2020'
				}
			}

			const createPaymentMethod = await this.stripe.createPaymentMethod(paymentSetup);

			//Return errors for payment method
			if ('error' in createPaymentMethod) {
				const error = createPaymentMethod.error;
				if (error.type === 'validation_error') {
					this.setMessage(createPaymentMethod.error.message);
					return 'error';
				}
				return 'error';
			}

			return createPaymentMethod.paymentMethod;
		},

		//Create stripe payment method with sepa_debit
		createIbanPaymentMethod: async function() {
			const paymentSetup = {
				type: 'sepa_debit',
				sepa_debit: this.iban,
				billing_details: {
					email: this.email,
					name: this.name,
					address: {
						line1: this.address1,
						line2: this.address2,
						city: this.city,
						state: this.region_alpha_2,
						country: this.country_alpha_2,
						postal_code: this.postalCode,
					}
				},
				metadata: {
					uid: auth.currentUser.uid,
					app: 'synfire-2020'
				}
			}

			const createPaymentMethod = await this.stripe.createPaymentMethod(paymentSetup);

			//Return errors for payment method
			if ('error' in createPaymentMethod) {
				const error = createPaymentMethod.error;
				if (error.type === 'validation_error') {
					this.setMessage(createPaymentMethod.error.message);
					return 'error';
				}
				return 'error';
			}

			return createPaymentMethod.paymentMethod;
		},

		//Create pseudo payment method for invoice only
		createInvoicePaymentMethod: function() {
			return {
				type: 'invoice',
				billing_details: {
					address: {
						line1: this.address1,
						line2: this.address2,
						city: this.city,
						state: this.region_alpha_2,
						country: this.country_alpha_2,
						postal_code: this.postalCode,
					}
				}
			};
		},

		//Complete stripe setup intent and aquire payment method
		createUpdatePayment: async function(type) {
			//Prevent event from double running
			if (this.creatingPayment) { return; }

			const inputCheck = await this.validateBilling();
			if (!inputCheck) { return; }

			//Set Loading Spinner----------------------------------
			this.creatingPayment = true;
			let paymentMethod = {};
			//-----------------------------------------------------
			// Create a payment method to use with the setup intent
			if (type === 'card') {
				paymentMethod = await this.createCardPaymentMethod();
				if (paymentMethod === 'error') {
					this.creatingPayment = false;
					return;
				}
			} else if (type === 'invoice') {
				paymentMethod = this.createInvoicePaymentMethod();
			} else {
				this.setMessage('Invalid payment method selected.')
				this.creatingPayment = false;
				return;
			}

			// if (type === 'iban') {
			// 	paymentMethod = await this.createIbanPaymentMethod();
			// 	// console.log(test_paymentMethod)
			// }

			//----------------------------------------------------
			//Assign payment method to the customer
			const result = await this.attachPayment(paymentMethod);
			if ('error' in result) {
				this.creatingPayment = false;
				this.setMessage(result.error.message);
				return;
			}
			if (debug) { console.log(result); }
			// stripe.confirmSepaDebitPayment

			//Check for payment intent (from open invoice) or setup intent
			//	If there is either then check if action is required and execute
			let intentStatus = {};
			if ('payment_intent' in result) {
				const client_secret = result.payment_intent.client_secret;
				intentStatus = await this.stripe.confirmCardPayment(client_secret)
			} else if ('setup_intent' in result) {
				const client_secret = result.setup_intent.client_secret;
				intentStatus = await this.stripe.confirmCardSetup(client_secret)
			}

			if ('error' in intentStatus) {
				this.setMessage(intentStatus.error.message)
			} else {
				this.setMessage('Your payment method was successfully updated')
				this.paymentConfirmed = true;
			}

			this.creatingPayment = false;

			this.resetUser('async')
		},

		//Call server to create customer and attach payment
		//	Calling with paymentMethod='invoice' removes all cards
		attachPayment: async function(paymentMethod) {
			const idToken = await auth.currentUser.getIdToken();
			const authToken = `Bearer ${idToken}`;
			const requestData = {
				payment_method: paymentMethod,
			};

			const requestOptions = {
				method: 'POST',
				data: requestData,
				url: process.env.VUE_APP_STRIPE_URL + '/manage',
				headers: {
					'Authorization': authToken,
					'Content-Type': 'application/json'
				},
				timeout: 10000,
			};

			let isError = false;
			const res = await axios(requestOptions).catch(() => { isError = true; });
			if (isError) {
				return {
					error: {
						type: 'api-error',
						message: 'Issues contancting the server. Please check your connection and try again.'
					}
				}
			}
			return res.data
		},

		//Check billing info and throw error for invalid data
		//	Returns false if any errors are thrown
		validateBilling: async function() {
			//Recheck region and country to account for autofilled inputs
			this.regionSubmit(this.region); //Check region for valid input
			await this.countrySubmit(this.country); //Check country for valid input

			let isError = this.errList.country || this.errList.region ? true : false;
			if (isError) { return false; } //Return immediately for country/region error

			//Prevent null strings and strings over 250char
			if (this.address2.length > 250 || this.region.length > 250) { isError = true; }
			for (let item in this.errList) {
				if (this[item].length > 250) {
					this.errList[item] = true;
					isError = true;
				} else {
					this.errList[item] = false;
				}
			}
			if (isError) { return false; }

			return true;
		},

		//Validate country input. Required for taxID
		//	Uses an API to obtain alpha2 country code
		countrySubmit: async function(input) {
			if (input.length < 2) { return; }
			this.errList.country = false;
			let isError = false;
			let url = '';
			if (input.length === 2 || input.length === 3) {
				const val = input.toLowerCase() === 'uk' ? 'gb' : input
				url = `${process.env.VUE_APP_COUNTRY_CODE_URL}/alpha/${encodeURI(val)}`;
				const res = await axios.get(url).catch(() => { isError = true; });
				if (isError || res.data.length < 1) {
					this.setMessage('Invalid country entry. Try the two or three digit country code.')
					this.errList.country = true;
					return;
				}
				this.country = res.data[0].name.common;
				this.country_alpha_2 = res.data[0].cca2;
			} else {
				url = `${process.env.VUE_APP_COUNTRY_CODE_URL}/name/${encodeURI(input)}`;
				const res = await axios.get(url).catch(() => { isError = true; });
				if (isError ||  res.data.length < 1) {
					this.setMessage('Invalid country. Try the two or three digit country code.')
					this.errList.country = true;
					return;
				}

				const countryList = res.data;
				let checkedList = [];

				//Double check list to see if native name was entered or the name is a direct match
				if (countryList.length > 1) {
					checkedList = countryList.filter((country) => country.nativeName === input || country.name === input);
				} else {
					checkedList = countryList;
				}

				if (checkedList.length !== 1) {
					this.errList.country = true;
				} else {
					this.country = checkedList[0].name.common;
					this.country_alpha_2 = checkedList[0].cca2;
				}
			} //endElse
			this.regionSubmit(this.region);
		},

		//Validate state/province. Required for tax ID
		//	Uses manual lists to get region alpha2 code
		regionSubmit: function(input) {
			if (this.country_alpha_2 !== 'CA' && this.country_alpha_2 !== 'US') {
				this.errList.region = false;
				return;
			}

			if(input === ''){
				this.errList.region = true;
				return;
			}

			let regionAlpha = [];
			let regionNames = [];

			if (this.country_alpha_2 === 'US') {
				regionAlpha = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];

				regionNames = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];
			}

			if (this.country_alpha_2 === 'CA') {
				regionAlpha = ['AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'];

				regionNames = ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Northwest Territories', 'Nova Scotia', 'Nunavut', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan', 'Yukon'];
			}

			const index_alpha = regionAlpha.indexOf(input);
			const index_name = regionNames.indexOf(input);
			if (index_alpha >= 0) {
				this.region_alpha_2 = regionAlpha[index_alpha];
				this.errList.region = false;
			} else if (index_name >= 0) {
				this.region_alpha_2 = regionAlpha[index_name];
				this.errList.region = false;
			} else {
				this.errList.region = true;
				this.setMessage('Invalid Province/State. Two digit codes may also be used.')
			}
		},
	},
}
</script>
<style>
.stripe-billing {
	height: 100%;
	width: 100%;
	/*overflow-y: auto;*/
}

.stripe-billing-section {
	margin-top: 1rem;
}

.stripe-billing-logo {
	height: 3rem;
	margin: .8rem 0;
}

.stripe-form__double-input {
	display: flex;
	margin: 1rem 0;
}

.stripe-form__double-divider {
	width: 1rem;
}


.stripe__card {
	min-height: 3rem;
	width: 100%;
	padding: .5rem;
	border-bottom: .1rem solid var(--color-bg-secondary-light);
	margin: 1rem 0;
}


@media(max-width: 500px) {
	.stripe-billing {
		height: 100%;
		width: 100%;
		overflow-y: hidden;
	}

	.stripe-form__double-input {
		flex-direction: column;
		/*margin: 1rem 0;*/
	}

	.stripe-form__double-divider {
		height: 1rem;
	}
}
</style>
'use strict';

export function sinD(theta) { return Math.sin(theta * Math.PI / 180); }

export function cosD(theta) { return Math.cos(theta * Math.PI / 180); }

export function tanD(theta) { return Math.tan(theta * Math.PI / 180); }

export function uuid() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
		var r = Math.random() * 16 | 0,
			v = c == 'x' ? r : (r & 0x3 | 0x8);
		return v.toString(16);
	});
}

//Safe acos function that rounds values close to 1 or 0 to 1 or 0
export function acos_fpe(val, precision = 7) {
	if (val > 1 || val < -1) {
		return Math.acos(round(val, precision));
	} else {
		return Math.acos(val);
	}
}

// Round to a set number of decimal places
export function round(value, precision) {
	const mult = 10 ** precision;
	return Math.round(mult * value) / mult;
}

// Deep copy utility function
export function copy(item) { return JSON.parse(JSON.stringify(item)); }
<template>
	<div class="navpanel">
		<!-- User name heading for panel -->
		<transition name="scale" mode="out-in">
			<div class="authpanel__userHeader" key="first-heading">
				<div class="userThumbnail">
					<img v-if="user.photoURL" :src="user.photoURL" alt="User Image" class="image-center">
				</div>
				<span v-if="user.displayName" class="authpanel__userHeading">{{user.displayName}}</span>
			</div>
		</transition>
		<!-- Panel Content -->
		<div class="navpanel-link-li">
			<ul class="text-list" @click="$emit('click-link')">
				<router-link to="/" tag="li" class="text-list__item" @click.prevent>Home</router-link>
				<router-link to="/lev" tag="li" class="text-list__item" @click.prevent>Linkage Designer</router-link>
				<router-link to="/db" tag="li" class="text-list__item" @click.prevent>File Managment</router-link>
				<router-link to="/team" tag="li" v-if="isTeamPanel" class="text-list__item">Team Management</router-link>
				<router-link to="/account" tag="li" class="text-list__item" @click.prevent>Account Settings</router-link>
				<a :href="docsURL" target="_blank" class="text-list__item">Help</a>
			</ul>
			<MessageList class=" margin--bottom" />
			<ButtonStandard @click="signOut" :name="'Logout'" :flex="true" />
		</div>
	</div>
</template>
<script>
import { auth } from "@/firebaseConfig.js";
import { mapState, mapActions } from "vuex";

import ButtonStandard from "@/components/functional/ButtonStandard.vue";
import MessageList from "@/components/MessageList.vue";


export default {
	name: "navpanel",
	components: {
		ButtonStandard,
		MessageList
	},


	computed: {
		...mapState('stateViewLev', ['user', 'claims', 'userInfo', 'isMobile']),

		isTeamPanel: function() {
			if (!this.userInfo) { return false; }
			if (!this.userInfo.planQty ||
				this.userInfo.planQty <= 1) { return false; }
			if (this.claims.team_id &&
				this.claims.team_id !== this.user.uid) { return false; }
			return true;
		},

		docsURL: function() { return process.env.VUE_APP_DOC_PAGE; }
	},

	methods: {
		...mapActions('stateViewLev', ['setViewData']),

		signOut: async function() {
			//Wait for logout to complete
			await auth.signOut();
			if (this.$router.currentRoute.path !== '/') { this.$router.push('/'); }
		},
	}

};
</script>
<style>
.navpanel {
	min-height: 15rem;
	width: 100%;
	overflow: hidden;
	padding: 1rem;

	background-color: var(--color-bg);
	color: var(--color-font-bg);
}

.navpanel-link-li {
	margin-top: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.authpanel__userHeader {
	height: 3rem;
	display: flex;
	/*font-family: var(--font-alt);*/
	font-weight: 500;
	margin-bottom: .5rem;
}

.userThumbnail {
	width: 3rem;
	height: 3rem;
	overflow: hidden;
	border-radius: 1.5rem;
	margin-right: .5rem;
}

.defaultThumb {
	width: 3rem;
	height: 3rem;
	border-radius: 1.5rem;
	border: none;
	margin-right: .5rem;
	background: var(--color-primary);
	line-height: 3rem;
	color: var(--color-font-primary);
}

.authpanel__userHeading {
	display: inline-block;
	line-height: 2rem;
	font-size: 2rem;
	padding: .5rem;
}

.text-list__item {
	display: block;
	height: 3rem;
	padding: .25rem .7rem;

	background-color: transparent;
	border: none;
	border-bottom: .1rem solid var(--color-bg-secondary-light);

	text-align: left;
	line-height: 2.5rem;
	font-weight: 500;
}

.text-list {
	margin-bottom: 0;
}

.text-list__item a {
	display: inline-block
}

.text-list__item:hover {
	color: var(--color-primary);
	cursor: pointer;
}
</style>
import axios from "axios";
import { auth } from "@/firebaseConfig.js";

export async function sendRequest(data, url, timeout = 7000) {
	const idToken = await auth.currentUser.getIdToken();
	const authToken = `Bearer ${idToken}`;

	const requestOptions = {
		method: 'POST',
		url: url,
		data: data,
		headers: {
			'Authorization': authToken,
			'Content-Type': 'application/json'
		},
		timeout: timeout,
	};

	let isError = false;
	const res = await axiosIPC(requestOptions).catch(() => { isError = true; });
	if (isError) {
		return {
			error: {
				type: 'connection-error',
				message: 'Issues contacting server. Please check your connection and try again.',
			}
		}
	}
	return res.data;
}

//Redirect axios calls through node for Electron build. Otherwise run axios as usual
export async function axiosIPC(request) {
	if (process.env.IS_ELECTRON) {
		const res = await window.ipcRenderer.invoke('ipc-axios', request);
		if (res.isError) { throw res.error; }
		return res;
	} else {
		const res = await axios(request)
		return res;
	}
}